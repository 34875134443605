import { animated, config, SpringConfig, useSpring } from 'react-spring-legacy';

export function AnimatedNumber({
	from = 0,
	to,
	className = '',
	animationConfig = config.molasses,
	roundNumber = true,
}: {
	from?: number;
	to: number;
	className?: string;
	animationConfig?: SpringConfig | ((key: string) => SpringConfig);
	roundNumber?: boolean;
}) {
	const spring = useSpring({
		config: { ...animationConfig },
		from: { value: from },
		value: to,
	});
	return (
		<animated.span className={className}>
			{/* @ts-ignore */}
			{spring.value.interpolate((value: number) =>
				roundNumber ? Math.round(value).toLocaleString() : Number(value.toFixed(1)).toLocaleString()
			)}
		</animated.span>
	);
}
