import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { useAllClassifiedContactPolicies } from '../../../../queries';
import { CustomDateRange } from '../../../components/CustomDateRange';
import { ISelectOption, Select } from '../../../components/Select';
import { PolicySelector } from '../../../components/email/PolicySelector';
import { AGE_RANGE_KEY, KeyFactErrors } from '../hooks/useKeyFactFilters';
import { styleSheet } from './styles';

interface ISelectOptionMinusId {
	dataContext: any;
	text: string;
}
const getOptions = (values: ISelectOptionMinusId[]) => {
	return values.map(({ dataContext, text }) => {
		return {
			dataContext,
			id: uuidgen(),
			text,
		};
	});
};

const MIN_AGE = 15;
const NO_VALUE_KEY = 'NoValue';
const noMinOption: ISelectOption = {
	dataContext: null,
	id: NO_VALUE_KEY,
	text: 'No Min Age',
};
const noMaxOption: ISelectOption = {
	dataContext: null,
	id: NO_VALUE_KEY,
	text: 'No Max Age',
};

const allAges: number[] = Array.from(Array(100 - MIN_AGE).keys());
const ageOptions: ISelectOption[] = [...allAges.map((number: number) => number + MIN_AGE)].map(age => {
	return {
		dataContext: age,
		id: age.toString(),
		text: age.toString(),
	};
});

const minAgeOptions: ISelectOption[] = [noMinOption, ...ageOptions];
const maxAgeOptions: ISelectOption[] = [noMaxOption, ...ageOptions];

const keyFactOptions = getOptions([
	{ dataContext: Api.KeyDateKind.Renewal, text: 'Renewals' },
	{ dataContext: Api.KeyDateKind.Anniversary, text: 'Anniversaries' },
	{ dataContext: AGE_RANGE_KEY, text: 'Age Range' },
	{ dataContext: Api.KeyDateKind.Birthday, text: 'Upcoming Birthdays' },
]);

export const KeyFactFiltersDropdown = ({
	selectedKeyFact,
	setSelectedKeyFact,
	selectedPolicies,
	setSelectedPolicies,
	selectedKeyFactStartDate,
	setSelectedKeyFactStartDate,
	selectedKeyFactEndDate,
	setSelectedKeyFactEndDate,
	selectedMinAge,
	setSelectedMinAge,
	selectedMaxAge,
	setSelectedMaxAge,
	keyFactErrors,
	className,
	styles,
}: {
	selectedKeyFact: Api.KeyDateKind | typeof AGE_RANGE_KEY | null;
	setSelectedKeyFact: React.Dispatch<React.SetStateAction<Api.KeyDateKind | typeof AGE_RANGE_KEY | null>>;
	selectedPolicies: string[];
	setSelectedPolicies: React.Dispatch<React.SetStateAction<string[]>>;
	selectedKeyFactStartDate: Date;
	setSelectedKeyFactStartDate: React.Dispatch<React.SetStateAction<Date>>;
	selectedKeyFactEndDate: Date;
	setSelectedKeyFactEndDate: React.Dispatch<React.SetStateAction<Date>>;
	selectedMinAge: number;
	setSelectedMinAge: React.Dispatch<React.SetStateAction<number>>;
	selectedMaxAge: number;
	setSelectedMaxAge: React.Dispatch<React.SetStateAction<number>>;
	keyFactErrors: string[];
	className?: string;
	styles?: StyleDeclarationValue[];
}) => {
	const onRenderKeyFactsOptionPlaceholder = React.useCallback(() => 'Select key fact', []);

	const contactsRequest: Api.IResourceSelectorContactsRequest = {
		filter: {},
	};

	const { data: classifiedPolicies, isLoading: isLoadingPolicies } = useAllClassifiedContactPolicies({
		contactsRequest,
		enabled: !!selectedKeyFact,
		refetchOnWindowFocus: false,
	});

	const onKeyFactOptionChanged = (value: ISelectOption) => {
		setSelectedKeyFact(value.dataContext);
	};

	const onSelectedPoliciesChanged = (values: string[]) => {
		setSelectedPolicies(values);
	};

	const onSelectedMinAgeChanged = (value: ISelectOption) => {
		setSelectedMinAge(value.dataContext);
	};

	const onSelectedMaxAgeChanged = (value: ISelectOption) => {
		setSelectedMaxAge(value.dataContext);
	};

	const ageRangeMessage = React.useMemo(() => {
		if (selectedKeyFact !== AGE_RANGE_KEY || !selectedKeyFactEndDate) {
			return '';
		}

		const estimatedDateOfFilterCreation = moment(selectedKeyFactEndDate).format('MMMM Do');
		const today = moment().format('MMMM Do');

		if (today === estimatedDateOfFilterCreation) {
			return 'as of today';
		}
		return ` as of ${estimatedDateOfFilterCreation} (when filter was created)`;
	}, [selectedKeyFactEndDate, selectedKeyFact]);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<Select
				styles={[styleSheet.fullWidth]}
				onOptionClick={onKeyFactOptionChanged}
				onRenderPlaceholder={onRenderKeyFactsOptionPlaceholder}
				options={keyFactOptions}
				selectedOption={keyFactOptions?.find(x => x.dataContext === selectedKeyFact)}
			/>

			{selectedKeyFact ? (
				<>
					{selectedKeyFact === Api.KeyDateKind.Renewal ? (
						<>
							<h6 className={css(styleSheet.subtitle)}>Policy</h6>
							<PolicySelector
								classifiedPolicies={classifiedPolicies}
								isLoadingClassifiedPolicies={isLoadingPolicies}
								onSelectedPoliciesChanged={onSelectedPoliciesChanged}
								selectedPolicies={selectedPolicies}
							/>

							{keyFactErrors.indexOf(KeyFactErrors.Policy) > -1 ? (
								<p role='alert' className={css(styleSheet.formErrorLine)}>
									Must select a policy for renewal
								</p>
							) : null}

							<h6 className={css(styleSheet.subtitle)}>Policy Renewal Date Range</h6>
						</>
					) : null}

					{selectedKeyFact === AGE_RANGE_KEY ? (
						<>
							<div className={css(styleSheet.twoColumn)}>
								<div className={css(styleSheet.fullWidth)}>
									<h6 className={css(styleSheet.subtitle)}>Min Age</h6>

									<Select
										onOptionClick={onSelectedMinAgeChanged}
										options={minAgeOptions}
										selectedOption={minAgeOptions.find(x => x.dataContext === selectedMinAge)}
									/>
								</div>

								<div className={css(styleSheet.fullWidth)}>
									<h6 className={css(styleSheet.subtitle)}>Max Age</h6>

									<Select
										onOptionClick={onSelectedMaxAgeChanged}
										options={maxAgeOptions}
										selectedOption={maxAgeOptions.find(x => x.dataContext === selectedMaxAge)}
									/>
								</div>
							</div>
							<span className={css(styleSheet.ageRange)}>{ageRangeMessage}</span>
						</>
					) : null}

					{selectedKeyFact === Api.KeyDateKind.Anniversary ? (
						<h6 className={css(styleSheet.subtitle)}>Anniversary Date Range</h6>
					) : null}

					{selectedKeyFact === Api.KeyDateKind.Birthday ? (
						<h6 className={css(styleSheet.subtitle)}>Birthday Date Range</h6>
					) : null}

					{selectedKeyFact !== AGE_RANGE_KEY ? (
						<CustomDateRange
							endDateRequired
							from={selectedKeyFactStartDate || null}
							to={selectedKeyFactEndDate || null}
							onChange={(from, to) => {
								setSelectedKeyFactStartDate(from);
								if (to !== from) {
									setSelectedKeyFactEndDate(to);
								}
							}}
							hasStartDateError={keyFactErrors.indexOf(KeyFactErrors.StartDate) > -1}
							hasEndDateError={keyFactErrors.indexOf(KeyFactErrors.EndDate) > -1}
							showTextInput
						/>
					) : null}
				</>
			) : null}
		</div>
	);
};
