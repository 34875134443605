import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useAdminUserSession } from '../../../../models/hooks/adminStateHooks';
import { useErrorMessages, useToaster } from '../../../../models/hooks/appStateHooks';
import { useCancelCriteriaContactFilterMutation } from '../../../../queries';
import { baseStyleSheet } from '../../../styles/styles';
import { AdvancedFiltersFlyout2 } from '../../AdvancedFiltersFlyout2';
import { Checkbox } from '../../Checkbox';
import { Collapsible } from '../../Collapsible';
import { LoadingSpinner } from '../../LoadingSpinner';
import { AutomationTriggerIcon } from '../../svgs/icons/AutomationTriggerIcon';
import { AdvancedOptionsButton } from '../AutomationStepCard/presentation';
import { AutomationCancelOnReplyCheckbox, AutomationTriggerSettings } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	automationTemplate?: Api.AutomationTemplateViewModel;
	className?: string;
	steps?: Api.AutomationTemplateEditorStep[];
	styles?: StyleDeclarationValue[];
}

interface IState {
	trigger?: Api.IAutomationTrigger;
	advancedOptionsToggled: boolean;
	advancedCancelOptions: boolean;
	cancelCriteria: Api.IContactFilterCriteria[];
	viewCriteriaFlyout: boolean;
}
const _AutomationTriggerCard = (props: IProps) => {
	const userSession = useAdminUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { className, styles, automationTemplate } = props;
	const { logApiError } = useEventLogging('AutomationTriggerCard');
	const updateCriteria = useCancelCriteriaContactFilterMutation({
		impersonationContext: automationTemplate?.impersonationContext,
		onSuccess: () => {
			toaster.push({
				message: 'Automation cancel criteria updated successfully',
				type: 'successMessage',
			});
		},
		onError: (error: any) => {
			errorMessages.pushApiError(error);
			logApiError('UpdateCancelCriteriaContactFilter-Error', error);
		},
	});

	const [state, setState] = React.useState<IState>({
		trigger: undefined,
		advancedOptionsToggled: false,
		advancedCancelOptions: false,
		cancelCriteria: [],
		viewCriteriaFlyout: false,
	});

	const trigger = React.useMemo(() => {
		return automationTemplate?.draftTriggerReference || automationTemplate?.publishedTriggerReference;
	}, [automationTemplate?.draftTriggerReference, automationTemplate?.publishedTriggerReference]);

	const onViewCriteria = React.useCallback((val: boolean) => {
		updateState({
			viewCriteriaFlyout: val,
		});
	}, []);

	React.useEffect(() => {
		if (automationTemplate?.contactFilter?.criteria?.length) {
			updateState({
				advancedOptionsToggled: true,
				advancedCancelOptions: true,
				cancelCriteria: automationTemplate.contactFilter.criteria,
			});
		}
	}, [automationTemplate?.contactFilter?.criteria]);

	React.useEffect(() => {
		updateState({
			trigger,
		});
	}, [trigger]);

	const setTrigger = async (_trigger: Api.IAutomationTrigger, fallbackTrigger?: Api.IAutomationTrigger) => {
		if (!Api.isValidAutomationTrigger(_trigger)) {
			updateState({
				trigger: _trigger,
			});
			return;
		}
		try {
			const opResult = await automationTemplate?.addTrigger(_trigger);
			if (opResult?.success) {
				updateState({
					trigger: _trigger,
				});
				toaster.push({
					message: 'Automation trigger set successfully',
					type: 'successMessage',
				});
			} else {
				errorMessages?.pushApiError(opResult);
			}
		} catch (err: any) {
			logApiError('SetTrigger-Error', err);
			errorMessages?.pushApiError(err);
			if (fallbackTrigger) {
				updateState({
					trigger: fallbackTrigger,
				});
			}
		}
	};

	const handleSaveCriteria = (criteria: Api.IContactFilterCriteria[]) => {
		if (!automationTemplate?.id) {
			return;
		}
		const newState: Partial<IState> = {
			viewCriteriaFlyout: false,
		};
		if (!criteria.length) {
			newState.advancedCancelOptions = false;
			return;
		}

		newState.cancelCriteria = criteria;
		updateCriteria.mutate({ id: automationTemplate.id, cancelCriteria: criteria });
		newState.advancedCancelOptions = true;
		updateState(newState);
	};

	const updateState = (newState: Partial<IState>) => {
		setState(prevState => ({
			...prevState,
			...newState,
		}));
	};

	const onDismissFlyout = () => {
		const nextState: Partial<IState> = {
			viewCriteriaFlyout: false,
		};
		if (!state.cancelCriteria?.length) {
			nextState.advancedCancelOptions = false;
		}
		updateState(nextState);
	};

	const handleAdvancedCancelOptionsChange = async () => {
		if (!automationTemplate?.id) {
			return;
		}
		const nextState: Partial<IState> = {
			advancedCancelOptions: !state.advancedCancelOptions,
		};
		if (!state.advancedCancelOptions && !state.cancelCriteria?.length) {
			nextState.viewCriteriaFlyout = true;
		}
		if (!nextState.advancedCancelOptions && state.cancelCriteria?.length) {
			updateCriteria.mutate({ id: automationTemplate.id, cancelCriteria: [] });

			nextState.cancelCriteria = [];
		}
		updateState(nextState);
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} automation-trigger-card ${className || ''}`}>
			<article className={css(styleSheet.cardHeader)}>
				<h4 className={css(styleSheet.cardHeaderText)}>Automation Type:</h4>
				{automationTemplate?.isLoaded ? (
					<div className={css(baseStyleSheet.horizontalStack)}>
						<AutomationTriggerIcon trigger={state.trigger} />
						<div>
							{Api.VmUtils.Automations.triggers.getAutomationTriggerTitle(
								state.trigger,
								// @ts-ignore
								automationTemplate?.isImpersonating ? automationTemplate?.impersonationContext : userSession
							)}
						</div>
					</div>
				) : (
					<LoadingSpinner type='small' />
				)}
			</article>
			<div className={css(styleSheet.cardBody)}>
				{automationTemplate?.isLoaded ? (
					<>
						{!!Api.VmUtils.Automations.triggers.triggerHasOptions(state.trigger) && (
							<AutomationTriggerSettings
								automationTemplate={automationTemplate}
								onRequestSetTrigger={setTrigger}
								trigger={state.trigger}
							/>
						)}
						<AutomationCancelOnReplyCheckbox automationTemplate={automationTemplate} />
					</>
				) : null}
				<div>
					<AdvancedOptionsButton
						isCollapsed={!state.advancedOptionsToggled}
						onClick={() =>
							updateState({
								advancedOptionsToggled: !state.advancedOptionsToggled,
							})
						}
					/>
					<Collapsible isCollapsed={!state.advancedOptionsToggled}>
						<>
							<Checkbox
								id='advancedCancelOptions'
								checked={state.advancedCancelOptions}
								type='large'
								onChange={handleAdvancedCancelOptionsChange}
							>
								<div>
									<p className={css(styleSheet.noMargin, styleSheet.checkboxText)}>
										Cancel if contact no longer meets criteria
									</p>
								</div>
							</Checkbox>
							{state.cancelCriteria?.length ? (
								<button className={css(styleSheet.ctaButton)} onClick={() => onViewCriteria(true)}>
									View Criteria
								</button>
							) : null}
						</>
					</Collapsible>
				</div>
			</div>
			<AdvancedFiltersFlyout2
				initialFilterCriteria={state.cancelCriteria}
				isOpen={state.viewCriteriaFlyout}
				onSave={handleSaveCriteria}
				onDismissFlyout={onDismissFlyout}
			/>
		</div>
	);
};

export const AutomationTriggerCard = observer(_AutomationTriggerCard);
