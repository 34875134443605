import * as Api from '@ViewModels';
import * as React from 'react';
import { useErrorMessages, useToaster, useUserSession } from '../../../../../../models/hooks/appStateHooks';

import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import {
	IContentCalendarSelectedSuggestion,
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	IMultiCampaignSchedulerResult,
	ModalChildComponentContextKey,
} from '../../../../../../models';
import { CampaignType } from '../../../../../../models/AdminModels';
import { shouldShowDuplicateTemplateWarning } from '../../../../../../models/Campaigns';
import { useEventLogging } from '../../../../../../models/Logging';
import { accountRequiresCompliance, getUniqueIdForSuggestion } from '../../../../../../models/UiUtils';
import { useTemplateQueries, useUserQuery } from '../../../../../../queries';
import { AdvancedOptionsButton } from '../../../../../components/automation/AutomationStepCard/presentation';
import { Button } from '../../../../../components/Button';
import { useCampaignCalendarSuggestionsContext } from '../../../../../components/campaigns/CampaignCalendar/context';
import { ComplianceApprovalPrompt } from '../../../../../components/campaigns/ComplianceApprovalPrompt';
import { Collapsible } from '../../../../../components/Collapsible';
import { LoadingSpinner } from '../../../../../components/LoadingSpinner';
import { PrivateRoute } from '../../../../../components/PrivateRoute';
import { ISelectOption } from '../../../../../components/Select';
import { InfoIcon } from '../../../../../components/svgs/icons/InfoIcon';
import { PopoverType, TinyPopover } from '../../../../../components/TinyPopover';
import { Toggle } from '../../../../../components/Toggle';
import { charmGray } from '../../../../../styles/colors';
import { bs } from '../../../../../styles/styles';
import { WarningBanner, WarningBannerMessage } from '../../../presentation';
import { MultiCampaignSchedulerLayout } from '../../MultiCampaignSchedulerLayout';
import { MultiCampaignTemplateItem } from '../../MultiCampaignTemplateItem';
import { useScheduleSocialMediaSuggestions, useSocialMediaTargetOptions } from './hooks';
import {
	MultiCampaignSocialMediaConnection,
	SocialMediaPostPreviewSelector,
	SocialMediaTemplateItem,
} from './presentation';
import { styleSheet } from './styles';

type SendFromOptions = {
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId: string | null;
};

interface MultiSocialSchedulerProps extends IImpersonationContextComponentProps, IModalContext {
	styles?: StyleDeclarationValue[];
}

function _MultiSocialScheduler({ styles = [], parentModal, impersonationContext }: MultiSocialSchedulerProps) {
	const { url } = useRouteMatch();
	const history = useHistory();
	const location = useLocation();
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('MultiSocialScheduler');
	const toaster = useToaster();
	const requiresCompliance = accountRequiresCompliance(userSession, impersonationContext);
	const [advancedOptionsToggled, setAdvancedOptionsToggled] = React.useState(false);
	const account = impersonationContext?.isValid ? impersonationContext.account : userSession.account?.toJs();
	const [overlayLogo, setOverlayLogo] = React.useState(() => {
		return account?.features.socialMedia.enabled && account?.features.socialMedia.alwaysOverlayLogo;
	});

	const hideWhiteBackground = React.useMemo(() => {
		return account?.features.socialMedia.enabled && account?.features.socialMedia.hideWhiteBackground;
	}, [account?.features.socialMedia.enabled, account?.features.socialMedia.hideWhiteBackground]);

	const {
		selectedSuggestions,
		deselectSuggestion,
		updateStartDateForSuggestion,
		getStartDateForSuggestion,
		onScheduleSuccess,
	} = useCampaignCalendarSuggestionsContext();

	const [sendFromOptions, setSendFromOptions] = React.useState<SendFromOptions>(() => {
		let sendFrom: Api.ISendEmailFrom;
		let sendFromUserId: string = null;
		if (impersonationContext?.isValid) {
			sendFrom = Api.SendEmailFrom.SelectedUser;
			sendFromUserId = impersonationContext.user?.id || null;
		} else {
			sendFrom = Api.SendEmailFrom.CurrentUser;
			sendFromUserId = userSession.user.id;
		}

		return {
			sendFrom,
			sendFromUserId,
		};
	});

	const authorId =
		sendFromOptions.sendFrom === Api.SendEmailFrom.SelectedUser ? sendFromOptions.sendFromUserId : userSession.user.id;
	const userQuery = useUserQuery({
		id: authorId,
		enabled: Boolean(authorId),
		impersonationContext: impersonationContext?.toJs(),
	});
	const selectedUser = userQuery.data;

	const templateQueries = useTemplateQueries({
		templateIds: selectedSuggestions.map(t => t.templateReference.templateId),
		refetchOnWindowFocus: false,
		queryKeyProvider: (_, index) => {
			const suggestion = selectedSuggestions[index];
			return getUniqueIdForSuggestion(suggestion);
		},
	});
	const scheduledPostsRef = React.useRef<Map<string, Api.ISocialMediaPost>>(new Map());
	const suggestionPostErrorsRef = React.useRef<Set<[IContentCalendarSelectedSuggestion, Api.IOperationResultNoValue]>>(
		new Set()
	);
	const scheduleMutation = useScheduleSocialMediaSuggestions({
		onSuccess: (results, params) => {
			results.forEach((result, i) => {
				const suggestion = params.suggestions[i];
				if (result.status === 'fulfilled') {
					const socialPost = result.value;
					scheduledPostsRef.current.set(getUniqueIdForSuggestion(suggestion), socialPost);
				} else {
					const error = Api.asApiError(result.reason);
					const matchingSuggestion = selectedSuggestions.find(
						s => getUniqueIdForSuggestion(suggestion) === getUniqueIdForSuggestion(s)
					);
					suggestionPostErrorsRef.current.add([matchingSuggestion, error]);
				}
			});

			if (scheduledPostsRef.current.size === selectedSuggestions.length) {
				const scheduled = Array.from(scheduledPostsRef.current?.values()) || [];
				const scheduleResult: IMultiCampaignSchedulerResult = {
					scheduledSuggestions: selectedSuggestions,
					withCompliance: params.sendWithCompliance,
				};

				toaster.push({
					message: `${scheduled.length} social posts scheduled.`,
					type: 'successMessage',
				});

				const event = onScheduleSuccess(scheduleResult);
				if (!event.defaultPrevented) {
					parentModal.onRequestClose();
				}
			}
		},
		onError: (error: Api.IOperationResultNoValue) => {
			logApiError('MultiCampaignSocialScheduler-Error', error as Api.IOperationResultNoValue);
			errorMessages.pushApiError(error as Api.IOperationResultNoValue);
			toaster.push({ message: 'An error occurred while scheduling the posts.', type: 'errorMessage' });
		},
	});

	const { facebookOptions, instagramOptions, linkedInOptions } = useSocialMediaTargetOptions({ user: selectedUser });
	const socialMediaOptions: {
		options: ISelectOption<Api.ISocialMediaConnection>[];
		provider: Api.SocialMediaType;
	}[] = React.useMemo(() => {
		return [
			{ options: facebookOptions, provider: Api.SocialMediaType.Facebook },
			{ options: instagramOptions, provider: Api.SocialMediaType.Instagram },
			{ options: linkedInOptions, provider: Api.SocialMediaType.LinkedIn },
		];
	}, [facebookOptions, instagramOptions, linkedInOptions]);

	React.useEffect(() => {
		if (!selectedUser) {
			return;
		}
		/**
		 * @If the user has a connected account, select the first connected account for each provider
		 * This is to ensure that the user doesn't have to manually select the connected account
		 */
		let newConnectionOptions: ISelectOption<Api.ISocialMediaConnection>[] = [];
		socialMediaOptions.forEach(({ options }) => {
			const connectedAccounts = options.filter(
				option => option.dataContext?.state === Api.SocialMediaConnectionState.Connected
			);
			if (options.length > 0 && connectedAccounts.length) {
				newConnectionOptions = newConnectionOptions.concat(connectedAccounts);
			}
		});
		setSelectedConnectionOptions(newConnectionOptions);
	}, [selectedUser, socialMediaOptions, sendFromOptions]);

	const [selectedConnectionOptions, setSelectedConnectionOptions] = React.useState<
		ISelectOption<Api.ISocialMediaConnection>[]
	>([]);

	const hasSelectedDisconnectedConnection = selectedConnectionOptions.some(option => {
		return option?.dataContext?.state !== Api.SocialMediaConnectionState.Connected;
	});

	const suggestionsWithoutDatesCount = selectedSuggestions.filter(s => !getStartDateForSuggestion(s)).length;

	const scheduleSuggestions = (sendWithCompliance = false, sendWithComplianceEmail?: string) => {
		scheduleMutation.mutate({
			impersonationContext: impersonationContext?.toJs(),
			forUserId: authorId,
			templates: templateQueries.map(t => t.data),
			suggestions: selectedSuggestions.map(suggestion => {
				return {
					...suggestion,
					schedule: {
						startDate: getStartDateForSuggestion(suggestion).toISOString(),
					},
					overlayLogo,
				} as IContentCalendarSelectedSuggestion;
			}),
			sendWithCompliance,
			sendWithComplianceEmail,
			designatedTargets: selectedConnectionOptions
				.map(connectionOption => {
					const connection = connectionOption.dataContext;
					if (connection?.postTargetId && connection?.userId) {
						return {
							provider: connection.type,
							pageId: connection.postTargetId,
							userId: connection.userId,
						};
					}
					return null;
				})
				.filter(Boolean),
		});
	};

	const canSchedule: boolean =
		suggestionsWithoutDatesCount === 0 &&
		!hasSelectedDisconnectedConnection &&
		!scheduleMutation.isLoading &&
		!templateQueries.some(q => q.isLoading) &&
		Object.values(selectedConnectionOptions).some(
			option => option?.dataContext?.state === Api.SocialMediaConnectionState.Connected
		) &&
		Boolean(impersonationContext?.isValid ? impersonationContext.user?.id : true);

	return (
		<div className={css(styleSheet.container, ...styles)}>
			{hasSelectedDisconnectedConnection ? (
				<WarningBanner>
					{selectedConnectionOptions.map(selectedConnectionOption => {
						const connection = selectedConnectionOption.dataContext;
						const provider = connection.type;
						if (connection && connection.state !== Api.SocialMediaConnectionState.Connected) {
							// TODO: update this for other states (e.g. no target)?
							return (
								<WarningBannerMessage key={connection.postTargetId}>
									<span>{`${Api.SocialMediaType[provider]} account: `}</span>
									<strong>{`@${connection?.postTargetDisplayName || connection?.userName}`}</strong>
									{` was disconnected ${moment(connection.expiresAt).format(
										'MM/DD/YYYY'
									)}. Please reconnect the account to schedule content on this platform.`}
								</WarningBannerMessage>
							);
						}
						return null;
					})}
				</WarningBanner>
			) : null}
			{suggestionsWithoutDatesCount > 0 ? (
				<WarningBanner>
					<>
						<WarningBannerMessage>
							{`You have `}
							<strong>{suggestionsWithoutDatesCount}</strong>
							{` without a send date. Please select a date/time to continue scheduling these posts.`}
						</WarningBannerMessage>
					</>
				</WarningBanner>
			) : null}
			<MultiCampaignSchedulerLayout
				{...sendFromOptions}
				hideContactOwnerOption
				title='Schedule Social Media Campaigns'
				onSendFromChange={nextAuthor => {
					impersonationContext?.updateUser({
						id:
							nextAuthor.sendFrom === Api.SendEmailFrom.SelectedUser ? nextAuthor.sendFromUserId : userSession.user.id,
					});
					setSendFromOptions(nextAuthor);
				}}
				styles={[styleSheet.scheduler]}
				isSocialMediaLayout
				sideBar={
					<Switch>
						<PrivateRoute path={`${url}/compliance-options`} userSession={userSession}>
							<ComplianceApprovalPrompt
								disabled={!canSchedule}
								isLoading={scheduleMutation.isLoading}
								campaignType={CampaignType.Social}
								onScheduleCompliance={(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string) => {
									e.preventDefault();
									scheduleSuggestions(true, sendWithComplianceEmail);
								}}
								onScheduleWithoutCompliance={() => scheduleSuggestions()}
							/>
						</PrivateRoute>
						<PrivateRoute userSession={userSession}>
							<SocialMediaPostPreviewSelector
								templateQueries={templateQueries}
								overlayLogo={overlayLogo}
								impersonationCtx={impersonationContext}
								hideWhiteBackground={hideWhiteBackground}
							/>
							<div className={css(bs.py4, bs.px4)}>
								{requiresCompliance ? (
									<Button
										className={css(bs.wFull)}
										onClick={() => {
											history.push({
												pathname: `${url}/compliance-options`,
												state: location.state,
											});
										}}
										label='Next: Compliance Options'
										disabled={!canSchedule}
									/>
								) : (
									<Button
										className={css(bs.wFull)}
										onClick={() => scheduleSuggestions(false)}
										disabled={!canSchedule || scheduleMutation.isLoading}
										isLoading={scheduleMutation.isLoading}
										label='Schedule on suggested dates'
									/>
								)}
								<div className={css(styleSheet.advancedOptions)}>
									<AdvancedOptionsButton
										isCollapsed={!advancedOptionsToggled}
										onClick={() => setAdvancedOptionsToggled(!advancedOptionsToggled)}
									/>
									<Collapsible isCollapsed={!advancedOptionsToggled}>
										{advancedOptionsToggled ? (
											<>
												<div className={css(bs.flex, bs.flexCenter, bs.gap2)}>
													<Toggle
														id='logo-toggle-multi-scheduler'
														isOn={overlayLogo}
														text={!overlayLogo ? 'Overlay Logo Off' : 'Overlay Logo On'}
														onToggleCheckChanged={() => {
															setOverlayLogo(!overlayLogo);
														}}
														uncheckedColor='#B1B4B6'
													/>
													<TinyPopover
														align='end'
														anchor={<InfoIcon fillColor={charmGray} />}
														autoCloseOtherPopoversOnHover
														dismissOnOutsideAction
														anchorStyles={[styleSheet.infoPopoverToggle]}
														placement={['top', 'right']}
														toggleOnHover
														type={PopoverType.lightBlue}
													>
														<div className={css(styleSheet.popover)}>
															This will overlay your account&apos;s logo on the post&apos;s media at the moment the post
															gets posted. Click on the image preview to see the overlay.
														</div>
													</TinyPopover>
												</div>
											</>
										) : null}
									</Collapsible>
								</div>
							</div>
						</PrivateRoute>
					</Switch>
				}
			>
				<div className={css(bs.flex, bs.gap4, styleSheet.socialMediaDropdowns)}>
					{socialMediaOptions.map(({ options, provider }) => (
						<MultiCampaignSocialMediaConnection
							options={options}
							selectedOptions={selectedConnectionOptions.filter(option => option.dataContext.type === provider)}
							provider={provider}
							onSelectionChanged={option => {
								const newSelectedConnectionOptions = [...selectedConnectionOptions];
								if (newSelectedConnectionOptions.includes(option)) {
									const index = newSelectedConnectionOptions.indexOf(option);
									newSelectedConnectionOptions.splice(index, 1);
								} else {
									newSelectedConnectionOptions.push(option);
								}
								setSelectedConnectionOptions(newSelectedConnectionOptions);
							}}
							clearSelection={() => {
								const newSelectedConnectionOptions = selectedConnectionOptions.filter(
									x => x.dataContext.type !== provider
								);
								setSelectedConnectionOptions(newSelectedConnectionOptions);
							}}
							key={`social-media-${provider}`}
						/>
					))}
				</div>
				<div className={css(bs.flex, bs.flexCol, bs.px6, bs.pt4, bs.pb2, bs.gap8, bs.overflowAuto)}>
					{templateQueries.map((templateQuery, i) => {
						const suggestion = selectedSuggestions[i];
						if (!templateQuery.isSuccess) {
							return <LoadingSpinner key={`${getUniqueIdForSuggestion(suggestion)}-loading`} type='small' />;
						}
						const template = templateQuery.data;

						const scheduledDate = getStartDateForSuggestion(selectedSuggestions[i]);

						return (
							<MultiCampaignTemplateItem
								onUpdateDate={(newDate: Date) => updateStartDateForSuggestion(suggestion, newDate)}
								key={getUniqueIdForSuggestion(suggestion)}
								name={template?.name || 'Loading...'}
								scheduleDate={scheduledDate}
								onRemove={() => deselectSuggestion(suggestion)}
								{...sendFromOptions}
								postedOn={shouldShowDuplicateTemplateWarning(template) ? template?.lastUsedByDate?.account : undefined}
							>
								<SocialMediaTemplateItem key={`socialMedia-template-${template.id}`} template={template} />
							</MultiCampaignTemplateItem>
						);
					})}
				</div>
			</MultiCampaignSchedulerLayout>
		</div>
	);
}

export const MultiSocialScheduler = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(observer(_MultiSocialScheduler));
