import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { AutomationsWorkflowGraphic } from '@WebComponents/svgs/graphics/AutomationsWorkflowGraphic';
import { CircularAddIcon } from '@WebComponents/svgs/icons/CircularAddIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ILocationState, InternalAccountIds } from '../../../models';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { SupportedIndustries } from '../../../models/MessageTemplates';
import { getIndustryName } from '../../../models/UiUtils';
import {
	AutomationTemplateViewModel,
	AutomationTemplatesViewModel,
	AutomationsOnHoldViewModel,
	IAccount,
	IOperationResultNoValue,
	Industry,
	ResourceSelectorId,
} from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { DefaultSelectBox, ISelectBoxOption } from '../../components/SelectBox';
import { AutomationCard } from '../../components/automation/AutomationCard';
import { AutomationReportingCard } from '../../components/automation/AutomationReportingCard';
import { AutomationsPendingCard } from '../../components/automation/AutomationsPendingCard';
import { darkGrayFontColor } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { INavigationItemProps } from '../MainContainer';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		INavigationItemProps,
		IFullscreenModalComponentProps {
	account?: IAccount;
	automationTemplates?: AutomationTemplatesViewModel;
	className?: string;
	createAutomationButtonStyles?: StyleDeclarationValue[];
	headerStyles?: StyleDeclarationValue[];
	hideAppBarHeader?: boolean;
	hideDescription?: boolean;
	hideOnHoldAutomations?: boolean;
	onRenderHeader?(): React.ReactNode;
	/** Return true if consumer will handle creation */
	onRequestCreate?(): boolean;
	/** Return true if consumer will handle editing */
	onRequestEdit?(automationTemplate: AutomationTemplateViewModel): boolean;
	styles?: StyleDeclarationValue[];
}

interface IState {
	industry?: Industry;
	loading?: boolean;
	isReporting?: boolean;
}

const IndustrySelectBoxOptions = SupportedIndustries.map<ISelectBoxOption<Industry>>(x => {
	return {
		title: getIndustryName(x),
		value: x as Industry,
	};
});

class _Automations extends React.Component<IProps, IState> {
	private mAutomationTemplates: AutomationTemplatesViewModel;
	@observable.ref private automationsOnHold: AutomationsOnHoldViewModel;
	constructor(props: IProps) {
		super(props);
		this.state = {
			isReporting: props.match.path.includes('reporting'),
			loading: true,
		};
		// @ts-ignore
		this.mAutomationTemplates = props.automationTemplates || new AutomationTemplatesViewModel(props.userSession);
		// @ts-ignore
		// @ts-ignore
		this.automationsOnHold = !props.hideOnHoldAutomations && new AutomationsOnHoldViewModel(props.userSession);
	}

	public componentDidMount() {
		const { logApiError, logEvent, errorMessages, location, hideOnHoldAutomations } = this.props;
		const promise = this.mAutomationTemplates.load({
			...AutomationTemplatesViewModel.defaultLoadOptions,
			forAnyTrigger: true,
		});
		if (promise) {
			// @ts-ignore
			logEvent('TemplatesLoad');
			promise
				.then(() => {
					this.setState({
						loading: false,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					// @ts-ignore
					logApiError('TemplatesLoad-Error', error);
					// @ts-ignore
					errorMessages.pushApiError(error);
					this.setState({
						loading: false,
					});
				});
		}
		if (location.state === 'pending' && !hideOnHoldAutomations) {
			const { fullscreenModal, userSession, account: accountOverride } = this.props;
			const account = accountOverride || userSession?.account;
			if (account?.features?.automation?.enabled) {
				this.automationsOnHold
					.loadAutomationsOnHold()
					?.then(() => {
						const locationState: ILocationState<AutomationsOnHoldViewModel, any> = {
							viewModel: this.automationsOnHold,
						};
						// @ts-ignore
						fullscreenModal.history.push({
							pathname: `/automationsonhold/${ResourceSelectorId.AutomationsOnHold}`,
							state: locationState,
						});
					})
					?.catch((err: IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('LoadAutomationsOnHold-Error', err);
						// @ts-ignore
						errorMessages.pushApiError(err);
					});
			}
		}
	}

	private getAutomationTemplates = (templates: 'active' | 'inactive' | 'industry') => {
		const { isReporting } = this.state;
		return (
			<>
				{this.mAutomationTemplates[`${templates}`].map((x: AutomationTemplateViewModel) => {
					return !isReporting ? (
						<AutomationCard
							automationTemplate={x}
							automationTemplates={this.mAutomationTemplates}
							key={x.id}
							onRequestEdit={this.onRequestEdit(x)}
							styles={[styleSheet.automationCard]}
						/>
					) : (
						<AutomationReportingCard
							automationTemplate={x}
							automationTemplates={this.mAutomationTemplates}
							key={x.id}
							styles={[styleSheet.automationCard]}
						/>
					);
				})}
			</>
		);
	};

	public render() {
		const { className, styles, routeContainerClassName, userSession } = this.props;
		const { isReporting, loading } = this.state;
		return (
			<div
				className={`${css(styleSheet.container, ...(styles || []))} automations-container ${className || ''} ${
					routeContainerClassName || ''
				}`}
				style={isReporting ? { backgroundColor: '#fff' } : {}}
			>
				{!isReporting ? this.renderHeaders() : null}
				<div className={css(styleSheet.body)}>
					{loading ? (
						<LoadingSpinner type='large' />
					) : (
						<>
							<div className={css(styleSheet.cardGrid)}>
								<h3 className={css(styleSheet.cardGridTitle)} style={isReporting ? { color: darkGrayFontColor } : {}}>
									Active Automations
								</h3>
								<div className={css(styleSheet.cardGridContent)}>{this.getAutomationTemplates('active')}</div>
							</div>
							<div className={css(styleSheet.cardGrid)}>
								<h3 className={css(styleSheet.cardGridTitle)} style={isReporting ? { color: darkGrayFontColor } : {}}>
									Inactive Automations
								</h3>
								<div className={css(styleSheet.cardGridContent)}>{this.getAutomationTemplates('inactive')}</div>
							</div>
							{/* @ts-ignore */}
							{/* @ts-ignore */}
							{(this.mAutomationTemplates?.industry?.length > 0 || !!InternalAccountIds.has(userSession.account.id)) &&
							!isReporting ? (
								<div className={css(styleSheet.cardGrid)}>
									<h3 className={css(styleSheet.cardGridTitle, styleSheet.sampleAutomationsSectionTitle)}>
										<span>Sample Automations</span>
										{this.renderIndustrySelector()}
									</h3>
									<div className={css(styleSheet.cardGridContent)}>{this.getAutomationTemplates('industry')}</div>
								</div>
							) : null}
						</>
					)}
				</div>
			</div>
		);
	}

	private renderHeaders() {
		const { loading } = this.state;
		const { hideOnHoldAutomations, hideAppBarHeader, hideDescription, headerStyles = [] } = this.props;
		return (
			<>
				{!hideAppBarHeader && (
					<MultiContainerHeader appBarHeader={<h1 className={css(baseStyleSheet.breadcrumbTitle)}>Automations</h1>} />
				)}
				<AutomationsPendingCard shouldHide={loading || hideOnHoldAutomations} />
				<div className={css(styleSheet.header, ...headerStyles)}>
					{!hideDescription && (
						<>
							<div className={css(styleSheet.description)}>
								<div className={css(styleSheet.descriptionTitle)}>What is an automation?</div>
								<div>
									An automation (in Levitate) can send a series of emails for you, create a series of action items that
									you find yourself repeating constantly, or a mixture of both! We&apos;re here to automate.
								</div>
							</div>
							<AutomationsWorkflowGraphic className={css(styleSheet.headerWorkflowGraphic)} />
						</>
					)}
					{this.renderCreateNewAutomationButton()}
				</div>
			</>
		);
	}

	private renderCreateNewAutomationButton() {
		const { createAutomationButtonStyles = [] } = this.props;
		const { loading } = this.state;

		const button = (
			<button
				className={css(styleSheet.createNewButton, ...createAutomationButtonStyles)}
				disabled={!!loading}
				onClick={this.onCreateButtonClicked}
			>
				<CircularAddIcon />
				<span>
					Create New
					<br />
					Automation
				</span>
			</button>
		);

		if (this.mAutomationTemplates.canCreate) {
			return button;
		}

		return null;
	}

	private renderIndustrySelector() {
		const { userSession } = this.props;
		// @ts-ignore
		if (!InternalAccountIds.has(userSession?.account?.id)) {
			return null;
		}
		const { industry } = this.state;
		const selectedOption =
			IndustrySelectBoxOptions.find(
				// @ts-ignore
				// @ts-ignore
				x => x.value === (industry ? industry : userSession.account?.toJs().additionalInfo.industry)
			) || IndustrySelectBoxOptions.find(x => x.value === Industry.Miscellaneous);
		return (
			<DefaultSelectBox
				className={css(styleSheet.levTemplatesHeaderIndustrySelector)}
				onSelectionChanged={this.onIndustryChanged}
				options={IndustrySelectBoxOptions}
				optionStyles={[styleSheet.levTemplatesHeaderIndustrySelectorOption]}
				selectedOption={selectedOption}
				triggerStyles={[styleSheet.levTemplatesHeaderIndustrySelectorOption]}
			/>
		);
	}

	private onRequestEdit = (template: AutomationTemplateViewModel) => () => {
		return this.props.onRequestEdit?.(template) || false;
	};

	private onIndustryChanged = (option: ISelectBoxOption<Industry>) => {
		const { logApiError, logEvent, errorMessages } = this.props;
		this.setState(
			{
				industry: option.value,
			},
			() => {
				// @ts-ignore
				const promise = this.mAutomationTemplates.loadTemplatesForIndustry(option.value);
				if (promise) {
					// @ts-ignore
					logEvent('LoadIndustryTemplates', { industry: option.value });
					promise.catch((error: IOperationResultNoValue) => {
						// @ts-ignore
						logApiError('LoadIndustryTemplates-Error', error);
						// @ts-ignore
						errorMessages.pushApiError(error);
					});
				}
			}
		);
	};

	private onCreateButtonClicked = () => {
		const { history, match, logInput, onRequestCreate } = this.props;
		if (!onRequestCreate?.()) {
			// @ts-ignore
			logInput('CreateAutomation', 'Click');
			history?.push({
				pathname: `${match.url}/new`,
			});
		}
	};
}

const AutomationsAsObserver = observer(_Automations);
const AutomationsWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey
)(AutomationsAsObserver);
export const Automations = withRouter(withEventLogging(AutomationsWithContext, 'Automations'));
