import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../../models/UiUtils';
import { brandPrimary, white } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	circle: {
		padding: 0,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: 860,
		width: '70vw',
	},
	imageContainer: {
		maxWidth: 500,
		position: 'relative',
		margin: '0 8px',
		...CssSelectors.allDescendants(
			{
				maxWidth: '100%',
			},
			'img'
		),
	},
	containerCenter: {
		display: 'flex',
		justifyContent: 'center',
	},
	infoWrapper: {
		width: '100%',
		border: `1px solid ${brandPrimary}`,
		background: hexToRGBA(brandPrimary, 0.05),
		padding: 8,
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 16,
		marginTop: 16,
		gap: 8,
		boxSizing: 'border-box',
		...CssSelectors.allDescendants(
			{
				color: brandPrimary,
				fontSize: 14,
				margin: 0,
			},
			'p'
		),
		...CssSelectors.allDescendants(
			{
				margin: 0,
				padding: 0,
			},
			'figure'
		),
	},
	mainImage: {
		borderBottomRightRadius: 8,
		borderBottomLeftRadius: 8,
	},
	logoWrapper: {
		position: 'absolute',
		right: 0,
		bottom: 4,
		borderTopLeftRadius: 8,
		padding: '4px 16px',
		boxSize: 'border-box',
		background: white,
		...CssSelectors.allDescendants(
			{
				maxWidth: 150,
			},
			'img'
		),
	},
	logoWrapperHideBackground: {
		background: 'transparent',
	},
	logoWrapperCapHeight: {
		...CssSelectors.allDescendants({
			maxHeight: 60,
		}),
	},
	pointLeft: {
		height: 15,
		transform: 'rotateZ(90deg)',
		width: 20,
	},
	pointRight: {
		height: 15,
		transform: 'rotateZ(270deg)',
		width: 20,
	},
	tabNavigation: {
		alignItems: 'center',
		display: 'flex',
		gap: 6,
		justifyContent: 'center',
		paddingBottom: 6,
		paddingTop: 6,
	},
});
