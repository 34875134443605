import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { getRatingIntFromText } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateGetGoogleBusinessReview,
	invalidateInfiniteReviews,
	usePostGoogleBusinessReply,
} from '../../../../queries';
import { Avatar2 } from '../../../../web/components/Avatar2';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TextArea } from '../../../../web/components/TextArea';
import { AIGoogleReviewResponse } from '../../../../web/components/ai/AIGoogleReviewResponse';
import { LocationPinIcon } from '../../../../web/components/svgs/icons/LocationPinIcon';
import { aceGray, brandPrimary, noteTintColor, white } from '../../../../web/styles/colors';
import { baseStyleSheet } from '../../../../web/styles/styles';
import WorkingLionImageUrl from '../../../assets/aiAssistantThinking.png';
import { Button } from '../../Button';
import { CloseButton } from '../../CloseButton';
import { ActiveGoogleBusinessReview } from '../../Happiness/DashboardGoogleBusinessProfileCard';
import { Modal } from '../../Modal';
import { StarRating } from '../../surveys/StarRating';
import { LeftChevron } from '../../svgs/icons/LeftChevron';
import { RightChevron } from '../../svgs/icons/RightChevron';
import { SuccessCheckIcon } from '../../svgs/icons/SuccessCheckIcon';
import { styleSheet } from './styles';

const AIThinkingOverlay = ({
	generationError,
	clearError,
}: {
	generationError: boolean;
	clearError: () => void;
}) => {
	return (
		<div className={css(styleSheet.thinkingOverlay)}>
			<div className={css(styleSheet.thinkingLionImage)}>
				<img src={WorkingLionImageUrl} />
			</div>
			<div className={css(styleSheet.thinkingModalMessages)}>
				{!generationError ? (
					<>
						<p className={css(styleSheet.pill)}>Putting the final touches on that response...</p>

						<div className={css(styleSheet.thinking)}>
							<span />
							<span />
							<span />
						</div>
					</>
				) : null}

				{generationError ? (
					<>
						<p className={css(styleSheet.pill)}>There was an error generating content...</p>

						<button className={css(baseStyleSheet.ctaButton)} onClick={clearError}>
							<span>Retry</span>
						</button>
					</>
				) : null}
			</div>
		</div>
	);
};

export const GoogleBusinessReplyModal = ({
	isOpen,
	activeReview,
	onCloseModal,
	isLoadingReviews,
	disableNext,
	carouselProgressText,
	disablePrevious,
	goToNext,
	onSuccess: onSuccessFromProps,
	remainingReviewsCount,
	goBack,
}: {
	isOpen: boolean;
	activeReview?: ActiveGoogleBusinessReview;
	onCloseModal(): void;
	isLoadingReviews: boolean;
	goToNext(): void;
	goBack(): void;
	onSuccess: (reviewId: string) => void;
	remainingReviewsCount: number;
	disableNext: boolean;
	disablePrevious: boolean;
	carouselProgressText: string;
}) => {
	const userSession = useUserSession();
	const [response, setResponse] = useState('');
	const [isGeneratingContent, setIsGeneratingContent] = useState(false);
	const [generationError, setGenerationError] = useState(false);
	const [showSuccessScreen, setShowSuccessScreen] = useState(false);

	const isAiEnabled = userSession.account?.features?.contentGeneration?.enabled;
	const reviewId = activeReview?.id;

	const onSuccess = () => {
		onSuccessFromProps(reviewId);
		setResponse('');
		invalidateGetGoogleBusinessReview(reviewId);
		invalidateInfiniteReviews();
		setShowSuccessScreen(true);
		setIsGeneratingContent(false);
	};

	const onError = () => {
		setGenerationError(true);
	};

	const clearError = () => {
		setIsGeneratingContent(false);
		setGenerationError(false);
	};

	const postGoogleBusinessReplyMutation = usePostGoogleBusinessReply({
		onSuccess,
	});

	const onPostReplyClicked = () => {
		postGoogleBusinessReplyMutation.reset();
		postGoogleBusinessReplyMutation.mutate({
			comment: response,
			reviewId,
		});
	};

	const onCancel = () => {
		setResponse('');
		invalidateGetGoogleBusinessReview(reviewId);
		onCloseModal();
	};

	const closeModal = () => {
		setShowSuccessScreen(false);
		onCloseModal();
	};

	const onTextChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setResponse(event.target.value || '');
	};

	const getDaysAgoText = (value: any) => {
		return moment(value).fromNow();
	};

	if (!isOpen) {
		return null;
	}

	const getInitial = (value: string) => {
		return value.substring(0, 1);
	};

	const isReplying = postGoogleBusinessReplyMutation.status === 'loading';
	const totalStars = 5;
	const showOverlay = isGeneratingContent || !!generationError;

	const renderReviewContent = () => {
		if (isLoadingReviews || !activeReview) {
			return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='small' />;
		}

		const rating = getRatingIntFromText(activeReview.rating);
		const reviewText = activeReview.text || '';

		return (
			<>
				<div className={css(styleSheet.reviewRow)}>
					<button
						onClick={goBack}
						className={css(
							styleSheet.navIconSection,
							styleSheet.borderRight,
							disablePrevious && baseStyleSheet.disabled
						)}
					>
						<LeftChevron height={40} width={40} colorFill={aceGray} />
					</button>
					<article className={css(styleSheet.reviewerColumn)}>
						<div className={css(styleSheet.responsesCount)}>{carouselProgressText}</div>
						<div className={css(styleSheet.reviewer)}>
							<div>
								<Avatar2
									fallbackText={getInitial(activeReview.reviewer.name)}
									imgSrc={activeReview.reviewer.profilePhotoUrl}
									styleDeclaration={[styleSheet.avatar]}
								/>
							</div>

							<div className={css(styleSheet.author)}>
								<span
									className={css(styleSheet.reviewerName)}
									title={activeReview.reviewer.name}
									style={{
										WebkitLineClamp: 2,
										WebkitBoxOrient: 'vertical',
										display: '-webkit-box',
										overflow: 'hidden',
									}}
								>
									{activeReview.reviewer.name}
								</span>

								<em className={css(styleSheet.timeAgo)}>Posted {getDaysAgoText(activeReview.updateTime)}</em>
							</div>
						</div>

						<div className={css(styleSheet.location)}>
							<LocationPinIcon className={css(styleSheet.locationIcon)} fillColor={brandPrimary} />
							{activeReview.locationDescription}
						</div>
					</article>

					<article className={css(styleSheet.reviewColumn)}>
						<div className={css(styleSheet.rating)}>
							<StarRating
								readonly
								fillColor={noteTintColor}
								total={totalStars}
								value={rating}
								starStyles={[styleSheet.ratingStar]}
							/>
						</div>

						<p className={css(styleSheet.reviewText)}>
							{reviewText || <i className={css(styleSheet.emptyComment)}>no comment added</i>}
						</p>
					</article>
					<button
						onClick={goToNext}
						className={css(styleSheet.navIconSection, styleSheet.borderLeft, disableNext && baseStyleSheet.disabled)}
					>
						<RightChevron height={40} width={40} colorFill={aceGray} />
					</button>
				</div>

				<div className={css(styleSheet.responseRow)}>
					{showSuccessScreen ? (
						<div className={css(styleSheet.successScreen)}>
							<h1>
								<SuccessCheckIcon /> Your Response has been posted
							</h1>
							{remainingReviewsCount ? <p>You have {remainingReviewsCount} more reviews ready for a reply</p> : null}
							<div className={css(baseStyleSheet.flex, baseStyleSheet.gap2)}>
								<Button onClick={() => setShowSuccessScreen(false)} label='See all reviews' kind='primary' />
								<Button onClick={closeModal} label='Close' kind='reverse' />
							</div>
						</div>
					) : null}

					{!showSuccessScreen ? (
						<>
							<section className={css(styleSheet.responseContainer)}>
								<article className={css(styleSheet.nonAiResponse)}>
									<h3 className={css(styleSheet.responseTitle)}>New Response</h3>

									<TextArea
										inputId='create-google-review-reply-input'
										value={response}
										onChange={onTextChanged}
										rows={9}
									/>

									{postGoogleBusinessReplyMutation.isError ? (
										<div className={css(styleSheet.errorContainer)}>
											There was an issue submitting your response. You can try again, or reach out to support.
										</div>
									) : null}

									{isReplying ? <LoadingSpinner type='small' /> : null}

									{!isReplying ? (
										<div className={css(styleSheet.buttonContainer)}>
											<button
												className={css(baseStyleSheet.ctaButton)}
												onClick={onPostReplyClicked}
												disabled={!response}
											>
												Post My Response
											</button>

											<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancel} disabled={isReplying}>
												Cancel
											</button>
										</div>
									) : null}
								</article>
							</section>

							{isAiEnabled ? (
								<section className={css(styleSheet.aiResponseContainer)}>
									<AIGoogleReviewResponse
										review={activeReview}
										onGeneratingChanged={(value: boolean) => setIsGeneratingContent(value)}
										onPostSuccess={() => onSuccess()}
										onError={onError}
									/>
								</section>
							) : null}
						</>
					) : null}
				</div>
			</>
		);
	};

	return (
		<Modal
			isOpen={isOpen}
			onAfterClose={closeModal}
			shouldCloseOnOverlayClick={false}
			className={css(styleSheet.modal)}
		>
			{showOverlay ? <AIThinkingOverlay generationError={generationError} clearError={clearError} /> : null}
			<section className={showOverlay ? css(baseStyleSheet.hidden) : ''}>
				<div className={css(styleSheet.header)}>
					<span>Google Review Response</span>
					<CloseButton onClick={onCancel} className={css(styleSheet.closeButton)} fillColor={white} />
				</div>
				<div className={css(styleSheet.body)}>{renderReviewContent()}</div>
			</section>
		</Modal>
	);
};
