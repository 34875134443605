import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { FormFieldType, IFormField } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { Button } from '../../components/Button';
import { DeprecatedCloseButton } from '../../components/DeprecatedCloseButton';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { TrashIcon } from '../../components/svgs/icons/TrashIcon';
import { TextInputFormField } from '../../components/TextInputFormField';
import { Toggle } from '../../components/Toggle';
import { darkGrayFontColor, grayIconFill, success } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { styleSheet } from './styles';

export const ImagePreviewSmall = ({
	onRemoveClick,
	isLoading,
	name,
	url,
}: {
	onRemoveClick: () => void;
	isLoading?: boolean;
	name?: string;
	url: string;
}) => {
	return (
		<>
			{!isLoading ? (
				<div className={css(styleSheet.logoWrap)}>
					<img className={css(styleSheet.logoImage)} src={url} alt={name} />{' '}
					<p className={css(baseStyleSheet.truncateText, styleSheet.noMargin, styleSheet.logoName)}>{name}</p>
					<DeprecatedCloseButton type='button' onClick={onRemoveClick} className={css(styleSheet.logoRemove)} />
				</div>
			) : (
				<>
					<LoadingSpinner type='tiny' />
				</>
			)}
		</>
	);
};

const AdditionalQuestionsBaseHeader = ({
	isOn,
	onToggleCheckChanged,
}: { isOn: boolean; onToggleCheckChanged: (checked: boolean) => void }) => {
	return (
		<section className={css(bs.flex, bs.flexRow, bs.flexBetween)}>
			<h3 className={css(styleSheet.sectionTitle)}>Additional Questions</h3>
			<Toggle
				checkedColor={success}
				id='edit-survey-additionalQuestions-toggle'
				isOn={isOn}
				onToggleCheckChanged={onToggleCheckChanged}
				text={isOn ? 'Enabled' : 'Disabled'}
				textStyles={[styleSheet.toggleText]}
				uncheckedColor={grayIconFill}
			/>
		</section>
	);
};

export const AdditionalQuestionsBase = ({
	fields,
	onUpdateFields,
	isAdditionalQuestionsDisabled,
	setIsAdditionalQuestionsDisabled,
	showError,
	errorMessage,
}: IImpersonationContextComponentProps & {
	fields: IFormField<string>[];
	onUpdateFields: (fields: IFormField<string>[]) => void;
	isAdditionalQuestionsDisabled: boolean;
	setIsAdditionalQuestionsDisabled: () => void;
	showError: boolean;
	errorMessage: string;
}) => {
	const onToggleCheckChanged = () => {
		setIsAdditionalQuestionsDisabled();
	};
	const handleRemoveQuestion = (index: number) => {
		const newFields = [...fields];
		newFields.splice(index, 1);
		onUpdateFields(newFields);
	};
	const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const newFields = [...fields];
		newFields[index].label = e.target.value;
		onUpdateFields(newFields);
	};
	const handleAddingQuestion = () => {
		const newFields = [...fields];
		newFields.push({ id: uuid(), label: '', fieldType: FormFieldType.String });
		onUpdateFields(newFields);
	};

	return (
		<section className={css(styleSheet.bottomSection)}>
			<div className={css(styleSheet.section, styleSheet.sectionFullWidth)}>
				<AdditionalQuestionsBaseHeader
					isOn={!isAdditionalQuestionsDisabled}
					onToggleCheckChanged={onToggleCheckChanged}
				/>
				<div className={css(styleSheet.formControlWrap)}>
					{fields.map((field, questionIndex) => {
						return (
							<div className={css(styleSheet.questionWrap)} key={`${field.id}-question-${questionIndex}`}>
								<>
									<TextInputFormField
										disabled={isAdditionalQuestionsDisabled}
										inputId={`edit-custom-${field.id}-question-${questionIndex}-input`}
										label={`Question ${questionIndex + 1}`}
										labelStyles={[styleSheet.label]}
										placeholder={!questionIndex ? 'Example: Any dietary restrictions?' : ``}
										onChange={e => onChange(e, questionIndex)}
										type='text'
										styles={[styleSheet.questionInput]}
										id={`edit-custom-${field.id}-question-${questionIndex}-input`}
										value={fields[questionIndex]?.label}
									/>
									{fields.length !== 1 ? (
										<Button
											type='button'
											kind='custom'
											className={css(styleSheet.deleteButton)}
											onClick={() => handleRemoveQuestion(questionIndex)}
											label={
												<>
													Remove additional question
													<TrashIcon fillColor={darkGrayFontColor} />
												</>
											}
										/>
									) : null}
								</>
							</div>
						);
					})}
					{showError && fields.length === 1 && fields[0].label === '' ? (
						<p className={css(styleSheet.errorMessage, styleSheet.noMargin)}>
							{errorMessage?.replace('fields[0].label', 'Custom field')}
						</p>
					) : null}
				</div>

				<footer className={css(bs.flex, bs.justifyEnd, styleSheet.addQuestionFooter)}>
					<Button
						type='button'
						kind='custom'
						className={css(styleSheet.addQuestionButton)}
						disabled={isAdditionalQuestionsDisabled}
						onClick={handleAddingQuestion}
						label='+ Add Question'
					/>
				</footer>
			</div>
		</section>
	);
};

export const AdditionalQuestions = inject(ImpersonationContextKey)(AdditionalQuestionsBase);
