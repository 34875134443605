import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { v4 as uuid } from 'uuid';
import { numberToCurrencyStringValue, openUrlInNewTab } from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateDonationFilter,
	invalidateDonorFilter,
	useDonationContactsMutation,
	useDonationFilterQuery,
	useDonationSummaryQuery,
	useDonorContactsMutation,
	useDonorFilterQuery,
	useDonorSummaryQuery,
	useExportDonationsByDonorsMutation,
	useExportDonationsMutation,
} from '../../../../queries/';

import { DeleteModal, DeleteType } from '../DeleteModal/';
import { styleSheet as dbStyleSheet } from '../styles';
import { EditDonationModal } from './EditDonationModal/';
import { styleSheet } from './styles';

import { Checkbox } from '../../../components/Checkbox';
import { CloseButton } from '../../../components/CloseButton';
import { dataGridStyles } from '../../../components/DataGrid/data-grid-styles';
import { ExportConfirmationModalV2 } from '../../../components/ExportConfirmation';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../components/MoreMenu';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../../components/NavigationBreadcrumbsBar';
import { ISelectOption, Select } from '../../../components/Select';
import { TextInput } from '../../../components/TextInput';
import { TinyPopover } from '../../../components/TinyPopover';
import { UsersActionBar } from '../../../components/UsersActionBar';
import { EntityChip } from '../../../components/chips/EntityChip';
import { Badge } from '../../../components/dataBoards/Badge';
import { AmountFilterDropdown } from '../../../components/dataBoards/donations/AmountFilterDropdown';
import { DateRangeFilterDropdown } from '../../../components/dataBoards/donations/DateRangeFilterDropdown';
import { DonationCampaignSearch } from '../../../components/dataBoards/donations/DonationCampaignSearch';
import { DonorDetailsFlyout } from './DonorDetailsFlyout';

import { brandSecondary, white } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';

import { useDebounceValue } from '../../../../hooks/useDebounceValue';
import { useEventLogging } from '../../../../models/Logging';
import {
	AmountFilterOption,
	DonationPropertyOption,
	DonationTypeOption,
} from '../../../components/dataBoards/donations/models';
import { SmallGraph } from '../../../components/svgs/graphics/SmallGraphGraphic';
import { DoubleArrowIcon } from '../../../components/svgs/icons/DoubleArrowIcon';
import { DropDownIcon } from '../../../components/svgs/icons/DropDownIcon';
import { EmptySearchIcon } from '../../../components/svgs/icons/EmptySearchIcon';
import { LightningBulbIcon } from '../../../components/svgs/icons/LightBulbIcon';
import { SearchIcon } from '../../../components/svgs/icons/SearchIcon';

export const DonationPropertyOptions = Object.keys(DonationPropertyOption).map(key => {
	const option: ISelectOption = {
		id: key,
		text: DonationPropertyOption[key as keyof typeof DonationPropertyOption],
		dataContext: key,
	};
	return option;
});

enum MenuItem {
	Edit = 'Edit',
	Delete = 'Delete',
}

export function DonationsBoard() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const { logApiError } = useEventLogging('DonationsBoard');
	const [key, setKey] = React.useState(uuid());

	const [selectionState, setSelectionState] = React.useState<Api.ESelectionState>(Api.ESelectionState.None);
	const [selectedDonationRows, setSelectedDonationRows] = React.useState<string[]>([]);
	const [selectedDonorRows, setSelectedDonorRows] = React.useState<string[]>([]);
	const [excludedContacts, setExcludedContacts] = React.useState<Api.IProjectedContact[]>([]);

	const [selectedSearchProperty, setSelectedSearchProperty] = React.useState(DonationPropertyOptions[0]);
	const [searchFieldValue, setSearchFieldValue] = React.useState('');
	const debouncedSearchFieldValue = useDebounceValue(searchFieldValue, 500);
	const [selectedCampaign, setSelectedCampaign] = React.useState<Api.IDonationCampaign>(null);

	const [selectedAmountFilter, setSelectedAmountFilter] = React.useState<AmountFilterOption>(
		AmountFilterOption.OneTime
	);
	const [minimumDonation, setMinimumDonation] = React.useState<string>('');
	const [maximumDonation, setMaximumDonation] = React.useState<string>('');

	const [selectedDonationType, setSelectedDonationType] = React.useState<DonationTypeOption>(
		DonationTypeOption.HasDonated
	);
	const [minimumDonationDate, setMinimumDonationDate] = React.useState<string>(null);
	const [maximumDonationDate, setMaximumDonationDate] = React.useState<string>(null);

	const [donationToEdit, setDonationToEdit] = React.useState<Api.IDonation>(null);
	const [donationToDelete, setDonationToDelete] = React.useState<Api.IDonation>(null);
	const [contactIdOfDonorToView, setContactIdOfDonorToView] = React.useState<string>(null);

	const [isAddDonationPopoverOpen, setIsAddDonationPopoverOpen] = React.useState(false);
	const [isEditDonationOpen, setIsEditDonationOpen] = React.useState(false);
	const [isDeleteDonationOpen, setIsDeleteDonationOpen] = React.useState(false);
	const [isViewDonationOpen, setIsViewDonationOpen] = React.useState(false);

	const locationName = 'Donations Board';
	const hasSearchValues =
		!!debouncedSearchFieldValue || selectedCampaign || minimumDonation || maximumDonation || minimumDonationDate;

	const isFilteringDonorName =
		selectedSearchProperty.text === DonationPropertyOption.DonorName && !!debouncedSearchFieldValue;
	const isFilteringLifetime = selectedAmountFilter === AmountFilterOption.Lifetime;
	const isFilteringNotDonated = selectedDonationType === DonationTypeOption.NotDonated;

	const isDonorMode = React.useMemo(() => {
		return isFilteringDonorName || isFilteringLifetime || isFilteringNotDonated;
	}, [isFilteringDonorName, isFilteringLifetime, isFilteringNotDonated]);
	const amountFilterDropdownText = React.useMemo(() => {
		if (!!selectedAmountFilter && !minimumDonation && !maximumDonation) {
			return 'Amount';
		}
		const duration = selectedAmountFilter === AmountFilterOption.OneTime ? '1-Time' : 'Lifetime';

		return `$${minimumDonation ?? '0'}${maximumDonation ? '-' + maximumDonation : '+'}/${duration}`;
	}, [selectedAmountFilter, minimumDonation, maximumDonation]);

	React.useEffect(() => {
		if (isDonorMode) {
			setSelectedDonationRows([]);
		} else {
			setSelectedDonorRows([]);
		}
		setSelectionState(Api.ESelectionState.None);
		setExcludedContacts([]);
	}, [isDonorMode]);

	const donationFilterCriteria = React.useMemo(() => {
		const criterias: Api.IDonationFilterCriteria[] = [];

		if (!isFilteringLifetime && (!!minimumDonation || !!maximumDonation)) {
			if (minimumDonation) {
				criterias.push({
					property: Api.DonationFilterProperty.Amount,
					op: Api.FilterOperator.Gt,
					value: minimumDonation,
				});
			}
			if (maximumDonation) {
				criterias.push({
					property: Api.DonationFilterProperty.Amount,
					op: Api.FilterOperator.Lt,
					value: maximumDonation,
				});
			}
		}

		if (!!minimumDonationDate && selectedDonationType === DonationTypeOption.HasDonated) {
			criterias.push({
				property: Api.DonationFilterProperty.Date,
				op: Api.FilterOperator.Gt,
				value: minimumDonationDate,
			});
			if (maximumDonationDate) {
				criterias.push({
					op: Api.FilterOperator.Lt,
					property: Api.DonationFilterProperty.Date,
					value: maximumDonationDate,
				});
			}
		}

		if (selectedCampaign?.id) {
			criterias.push({
				property: Api.DonationFilterProperty.Campaign,
				value: selectedCampaign.id,
			});
		}

		return criterias;
	}, [
		isFilteringLifetime,
		maximumDonation,
		minimumDonation,
		minimumDonationDate,
		maximumDonationDate,
		selectedCampaign,
		selectedDonationType,
	]);

	const donorFilterCriteria = React.useMemo(() => {
		const criterias: Api.IDonorFilterCriteria[] = [];

		if (debouncedSearchFieldValue) {
			criterias.push({
				property: Api.DonorFilterProperty.Name,
				value: debouncedSearchFieldValue,
			});
		}

		if (isFilteringLifetime && (!!minimumDonation || !!maximumDonation)) {
			if (minimumDonation) {
				criterias.push({
					property: Api.DonorFilterProperty.Lifetime,
					op: Api.FilterOperator.Gt,
					value: minimumDonation,
				});
			}
			if (maximumDonation) {
				criterias.push({
					property: Api.DonorFilterProperty.Lifetime,
					op: Api.FilterOperator.Lt,
					value: maximumDonation,
				});
			}
		}

		if (!!minimumDonationDate && isFilteringNotDonated) {
			const compoundCriteria = {
				criteria: [
					{
						op: Api.FilterOperator.Lt,
						property: Api.DonorFilterProperty.LastDonationDate,
						value: minimumDonationDate,
					},
				],
				op: Api.FilterOperator.Not,
				property: Api.DonorFilterProperty.LastDonationDate,
			};
			criterias.push(compoundCriteria);
		}

		return criterias;
	}, [
		isFilteringLifetime,
		isFilteringNotDonated,
		maximumDonation,
		minimumDonation,
		minimumDonationDate,
		debouncedSearchFieldValue,
	]);

	const donorFilterQuery = useDonorFilterQuery({
		enabled: isDonorMode && donorFilterCriteria.length > 0,
		filterRequest: {
			criteria: donorFilterCriteria,
		},
	});
	const donors = React.useMemo(() => {
		return donorFilterQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [donorFilterQuery.data?.pages]);

	const donationFilterQuery = useDonationFilterQuery({
		enabled: !isDonorMode,
		filterRequest: {
			criteria: donationFilterCriteria,
		},
	});

	const donationSummaryQuery = useDonationSummaryQuery({
		enabled: !isDonorMode,
		filterRequest: {
			criteria: donationFilterCriteria,
		},
	});

	const donorSummaryQuery = useDonorSummaryQuery({
		enabled: isDonorMode,
		filterRequest: {
			criteria: donorFilterCriteria,
		},
	});

	const summary = React.useMemo(() => {
		if (isDonorMode) {
			return donorSummaryQuery.data;
		}
		return donationSummaryQuery.data;
	}, [donationSummaryQuery.data, donorSummaryQuery.data, isDonorMode]);

	const refreshDonations = () => {
		setDonationToEdit(null);
		invalidateDonationFilter({
			filterRequest: {
				criteria: donationFilterCriteria,
			},
		});
		invalidateDonorFilter({
			filterRequest: {
				criteria: donorFilterCriteria,
			},
		});
	};

	const donationContactsMutation = useDonationContactsMutation();
	const donorContactsMutation = useDonorContactsMutation();
	const exportDonationsMutation = useExportDonationsMutation();
	const exportDonationsByDonorsMutation = useExportDonationsByDonorsMutation();
	const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);

	const getMatchingContacts = async () => {
		setActionButtonsDisabled(true);
		let results: Api.IProjectedContact[] = [];
		if (selectionState === Api.ESelectionState.Some) {
			results = !isDonorMode
				? donations.filter(donation => selectedDonationRows.indexOf(donation.id) > -1).map(donation => donation.contact)
				: donors.filter(donor => selectedDonorRows.indexOf(donor.contactId) > -1).map(donor => donor.contact);
		} else {
			try {
				const apiResults = isDonorMode
					? await donorContactsMutation.mutateAsync({
							filterRequest: {
								criteria: donorFilterCriteria,
							},
						})
					: await donationContactsMutation.mutateAsync({
							filterRequest: {
								criteria: donationFilterCriteria,
							},
						});
				const excludedContactIdsMap = new Set(excludedContacts.map(contact => contact.id));
				results = apiResults.filter(contact => !excludedContactIdsMap.has(contact.id));
			} catch (error) {
				logApiError('ResolveContacts-Error', error);
			}
		}
		setActionButtonsDisabled(false);
		return results;
	};

	const donations = React.useMemo(() => {
		return donationFilterQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [donationFilterQuery.data]);

	const allToggleState = isDonorMode
		? selectedDonorRows.length === donors.length
			? 'all'
			: selectedDonorRows.length > 0
				? 'some'
				: 'none'
		: selectedDonationRows.length === donations.length
			? 'all'
			: selectedDonationRows.length > 0
				? 'some'
				: 'none';

	const onToggleDonationRow = (donation: Api.IDonation) => {
		const currentIndex = selectedDonationRows.indexOf(donation.id);
		const nextSelectedRows = [...selectedDonationRows];

		if (selectionState === Api.ESelectionState.All) {
			const nextExcludedContacts = [...excludedContacts];
			if (currentIndex > -1) {
				nextExcludedContacts.push(donation.contact);
			} else {
				nextExcludedContacts.splice(currentIndex, 1);
			}
			setExcludedContacts(nextExcludedContacts);
		} else {
			const isLastSelectedContact = currentIndex > -1 && selectedDonationRows.length === 1;
			if (isLastSelectedContact) {
				setSelectionState(Api.ESelectionState.None);
			} else if (nextSelectedRows) {
				setSelectionState(Api.ESelectionState.Some);
			}
		}

		if (currentIndex > -1) {
			nextSelectedRows.splice(currentIndex, 1);
		} else {
			nextSelectedRows.push(donation.id);
		}
		setSelectedDonationRows(nextSelectedRows);
	};

	const onToggleDonorRow = (donor: Api.IDonor) => {
		const currentIndex = selectedDonorRows.indexOf(donor.contactId);
		const nextSelectedRows = [...selectedDonorRows];

		if (selectionState === Api.ESelectionState.All) {
			const nextExcludedContacts = [...excludedContacts];
			if (currentIndex > -1) {
				nextExcludedContacts.push(donor.contact);
			} else {
				nextExcludedContacts.splice(currentIndex, 1);
			}
			setExcludedContacts(nextExcludedContacts);
		} else {
			const isLastSelectedContact = currentIndex > -1 && selectedDonorRows.length === 1;
			if (isLastSelectedContact) {
				setSelectionState(Api.ESelectionState.None);
			} else if (nextSelectedRows) {
				setSelectionState(Api.ESelectionState.Some);
			}
		}

		if (currentIndex > -1) {
			nextSelectedRows.splice(currentIndex, 1);
		} else {
			nextSelectedRows.push(donor.contactId);
		}
		setSelectedDonorRows(nextSelectedRows);
	};

	const onToggleSelectAll = () => {
		if (isDonorMode) {
			if (selectedDonorRows.length > 0) {
				setSelectionState(Api.ESelectionState.None);
				setSelectedDonorRows([]);
			} else {
				setSelectionState(Api.ESelectionState.All);
				setSelectedDonorRows(donors.map(donor => donor.contactId));
			}
		}
		if (selectedDonationRows.length > 0) {
			setSelectionState(Api.ESelectionState.None);
			setSelectedDonationRows([]);
		} else {
			setSelectionState(Api.ESelectionState.All);
			setSelectedDonationRows(donations.map(donation => donation.id));
		}
	};

	const clearSearch = () => {
		const donorNameOption = DonationPropertyOptions[0];
		setSelectedSearchProperty(donorNameOption);
		setSearchFieldValue('');
		setSelectedCampaign(null);
		setMinimumDonation('');
		setMaximumDonation('');
		setMinimumDonationDate('');
		setMaximumDonationDate('');
		setSelectedDonationType(DonationTypeOption.HasDonated);
		setSelectedAmountFilter(AmountFilterOption.OneTime);
	};

	const onSearchFieldInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchFieldValue(e.target.value);
	};

	const addDonationClicked = () => {
		setDonationToEdit({} as Api.IDonation);
		setIsAddDonationPopoverOpen(false);
		setIsEditDonationOpen(true);
	};

	const editDonation = (donation: Api.IDonation) => {
		setDonationToEdit(donation);
		setIsEditDonationOpen(true);
	};

	const deleteDonation = (donation: Api.IDonation) => {
		setDonationToDelete(donation);
		setIsDeleteDonationOpen(true);
	};

	const importClicked = () => {
		setIsAddDonationPopoverOpen(false);
		fullscreenModal.history.push(`/dataBoards/donations/import`);
	};

	const selectSearchProperty = (selectedOption: ISelectOption<DonationPropertyOption>) => {
		setSearchFieldValue('');
		setSelectedCampaign(null);
		setSelectedSearchProperty(selectedOption);
	};

	const selectDonationCampaign = (donationCampaign: Api.IDonationCampaign) => {
		setSelectedCampaign(donationCampaign);
	};

	const onCampaignSearchClear = () => {
		setSelectedCampaign(null);
	};

	const viewDetails = (contactId: string) => {
		setIsViewDonationOpen(true);
		setContactIdOfDonorToView(contactId);
	};

	const onDeleteConfirmed = () => {
		setIsDeleteDonationOpen(false);
		refreshDonations();
	};

	const onExport = () => {
		if (isDonorMode) {
			exportDonationsByDonorsMutation.mutate({
				filterRequest: {
					criteria: donorFilterCriteria,
				},
			});
		} else {
			exportDonationsMutation.mutate({
				filterRequest: {
					criteria: donationFilterCriteria,
				},
			});
		}
	};

	const canEdit = !userSession.hasNonProfitIntegrationConfigured;

	const onEntityClicked = (contactId: string, e: React.MouseEvent<HTMLDivElement>) => {
		const path = `${window.location.origin}/#/people/${contactId}`;
		if (e.ctrlKey || e.metaKey) {
			openUrlInNewTab(path);
			e.preventDefault();
		}
		// Else let the EntityChip handle the click
	};

	const isLoading = isDonorMode ? donorFilterQuery.isLoading : donationFilterQuery.isLoading;
	const noResultsFound =
		!isLoading && isDonorMode
			? donorFilterQuery.isFetched && donors.length === 0
			: donationFilterQuery.isFetched && donations.length === 0;

	const VIEW_ONLY_KEY = 'ViewOnlyMode_Alert';

	const showCard = !!localStorage.getItem(VIEW_ONLY_KEY) !== true;
	const onClose = () => {
		localStorage.setItem(VIEW_ONLY_KEY, 'true');
		refreshFromLocalStorage();
	};

	const refreshFromLocalStorage = () => {
		setKey(uuid());
	};

	const openAdvancedReporting = () => {
		fullscreenModal.history.push('/reporting/advanced-donations-reporting');
	};

	return (
		<div className={css(styleSheet.container)} key={key}>
			<MultiContainerHeader
				appBarHeader={
					<div className={css(styleSheet.breadcrumbsBar)}>
						<NavigationBreadcrumbsBar
							currentLocationName={locationName}
							pathComponentNameProvider={() => 'Data Boards'}
						/>
						<div className={css(styleSheet.banner)}>
							<SmallGraph />
							<a
								href='#'
								className={css(styleSheet.bannerLink)}
								onClick={ev => {
									ev.preventDefault();
									openAdvancedReporting();
								}}
							>
								Check our advanced reporting{' '}
								<DoubleArrowIcon fillColor={brandSecondary} style={{ marginLeft: '0.5rem' }} />
							</a>
						</div>
					</div>
				}
				fullscreenHeader={locationName}
			/>

			{!canEdit && showCard ? (
				<div className={css(styleSheet.viewOnlyModeBannerContainer)}>
					<div className={css(styleSheet.viewOnlyModeBannerContent)}>
						<LightningBulbIcon />
						<div>
							<h4 className={css(styleSheet.infoHeaderContentBold)}>View Only Mode</h4>
							<div>When DonorPerfect is enabled you cannot make edits to the donation data through Levitate.</div>
						</div>
					</div>
					<CloseButton onClick={onClose} />
				</div>
			) : null}

			<header className={css(dbStyleSheet.header)}>
				<div className={css(dbStyleSheet.propertySearch)}>
					<Select
						styles={[dbStyleSheet.searchPropertySelector]}
						onOptionClick={selectedOption => selectSearchProperty(selectedOption)}
						options={DonationPropertyOptions}
						selectedOption={selectedSearchProperty}
					/>

					{selectedSearchProperty.text === DonationPropertyOption.DonorName ? (
						<TextInput
							inputId='donor-name-filter-input'
							type='text'
							className={css(dbStyleSheet.comboBoxRightAutocomplete)}
							inputClassName={css(dbStyleSheet.wideInput)}
							onChange={onSearchFieldInputChanged}
							value={searchFieldValue}
							leftAccessory={<SearchIcon className={css(dbStyleSheet.searchIcon)} />}
							placeholder='Search'
						/>
					) : null}

					{selectedSearchProperty.text === DonationPropertyOption.Campaign ? (
						<DonationCampaignSearch
							className={css(dbStyleSheet.comboBoxRight, dbStyleSheet.wideInput)}
							onSelectDonationCampaign={selectDonationCampaign}
							dropdownClassName={css(dbStyleSheet.comboBoxDropdown)}
							canAdd={false}
							onClear={onCampaignSearchClear}
						/>
					) : null}
				</div>

				<div className={css(dbStyleSheet.mediumInput)}>
					<AmountFilterDropdown
						key={`amountFilterDropdown-${selectedAmountFilter}-${minimumDonation}-${maximumDonation}`}
						label={amountFilterDropdownText}
						initialAmountFilterOption={selectedAmountFilter}
						initialMinimumDonation={minimumDonation}
						initialMaximumDonation={maximumDonation}
						onChange={value => {
							setSelectedAmountFilter(value.amountFilterOption);
							setMinimumDonation(value.minimumDonation);
							setMaximumDonation(value.maximumDonation);
						}}
					/>
				</div>

				<div className={css(dbStyleSheet.mediumInput)}>
					<DateRangeFilterDropdown
						minimumDonationDate={minimumDonationDate}
						setMinimumDonationDate={setMinimumDonationDate}
						maximumDonationDate={maximumDonationDate}
						setMaximumDonationDate={setMaximumDonationDate}
						selectedDonationType={selectedDonationType}
						setSelectedDonationType={setSelectedDonationType}
						showCustomRange={!isFilteringNotDonated}
					/>
				</div>

				{hasSearchValues ? (
					<div className={css(dbStyleSheet.clearSearch)}>
						<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={clearSearch}>
							<span>Clear Search</span>
						</button>
					</div>
				) : null}
			</header>

			<header className={css(dbStyleSheet.header, dbStyleSheet.indented)}>
				<div className={css(dbStyleSheet.leftHeader)}>
					<UsersActionBar
						getMatchingContacts={getMatchingContacts}
						onExport={onExport}
						selectionState={selectionState}
						actionButtonsDisabled={actionButtonsDisabled}
					/>
				</div>

				{canEdit ? (
					<div className={css(dbStyleSheet.rightHeader)}>
						<TinyPopover
							anchor={
								<div className={css(dbStyleSheet.addButtonContainer)}>
									<button
										className={css(baseStyleSheet.ctaButton, dbStyleSheet.addButton)}
										onClick={addDonationClicked}
									>
										<span>Add Donation</span>
									</button>
									<button className={css(dbStyleSheet.dropdownCaret)} onClick={() => setIsAddDonationPopoverOpen(true)}>
										<DropDownIcon fillColor={white} />
									</button>
								</div>
							}
							isOpen={isAddDonationPopoverOpen}
							dismissOnOutsideAction={true}
							align='center'
							placement={['bottom']}
							onRequestClose={() => setIsAddDonationPopoverOpen(false)}
						>
							<button
								className={css(baseStyleSheet.ctaButtonReverse, dbStyleSheet.importButton)}
								onClick={importClicked}
							>
								Import from CSV
							</button>
						</TinyPopover>
					</div>
				) : null}
			</header>

			<div className={css(dbStyleSheet.tableContainer)}>
				<table className={css(dataGridStyles.table, dbStyleSheet.tableOverrides)}>
					<thead className={css(dataGridStyles.tableHeader)}>
						<tr>
							<th className={css(dataGridStyles.tableHeaderCell)}>
								<Checkbox
									checked={allToggleState === 'all'}
									id='select-all-donations'
									onChange={onToggleSelectAll}
									partial={allToggleState === 'some'}
								/>
							</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Donor Name</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Contact</th>
							{!isDonorMode ? <th className={css(dataGridStyles.tableHeaderCell)}>Donation Date</th> : null}
							{isDonorMode ? <th className={css(dataGridStyles.tableHeaderCell)}>Donation Count</th> : null}
							<th className={css(dataGridStyles.tableHeaderCell)}>{isDonorMode ? 'Lifetime Donations' : 'Amount'}</th>
							{!isDonorMode ? <th className={css(dataGridStyles.tableHeaderCell)}>Campaign</th> : null}
							<th className={css(dataGridStyles.tableHeaderCell)}>&nbsp;</th>
						</tr>
					</thead>

					<tbody>
						{!isDonorMode && !isLoading
							? donations.map(donation => {
									return (
										<tr key={`donation-${donation.id}`}>
											<td className={css(dataGridStyles.tableDataCell)}>
												<Checkbox
													type='large'
													checked={selectedDonationRows.indexOf(donation.id) > -1}
													id={donation.id}
													onChange={() => onToggleDonationRow(donation)}
												/>
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												<button onClick={() => viewDetails(donation.contactId)}>
													<h6 className={css(dbStyleSheet.name, baseStyleSheet.truncateText, baseStyleSheet.fontBold)}>
														{donation.donorName}
													</h6>
												</button>
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{donation.contact ? (
													<EntityChip
														className={css(dbStyleSheet.contact)}
														key={donation.contact.id}
														entity={new Api.ContactViewModel(userSession, donation.contact)}
														readOnly={true}
														onClick={(e: React.MouseEvent<HTMLDivElement>) => onEntityClicked(donation.contactId, e)}
													/>
												) : null}
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{moment(donation.date).format('MM/DD/YYYY')}
											</td>
											<td className={css(dataGridStyles.tableDataCell, dbStyleSheet.amountText)}>
												${numberToCurrencyStringValue(donation.amount)}
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{donation.campaign ? (
													<Badge text={donation.campaign.name} foregroundColor={donation.campaign.foregroundColor} />
												) : null}
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{canEdit ? (
													<MoreMenu>
														<MoreMenuItem onClick={() => editDonation(donation)}>{MenuItem.Edit}</MoreMenuItem>
														<MoreMenuItem onClick={() => deleteDonation(donation)}>{MenuItem.Delete}</MoreMenuItem>
													</MoreMenu>
												) : null}
											</td>
										</tr>
									);
								})
							: null}

						{isDonorMode && !isLoading
							? donors.map(donor => {
									return (
										<tr key={`donor-${donor.contactId}`}>
											<td className={css(dataGridStyles.tableDataCell)}>
												<Checkbox
													type='large'
													checked={selectedDonorRows.indexOf(donor.contactId) > -1}
													id={donor.contactId}
													onChange={() => onToggleDonorRow(donor)}
												/>
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												<button onClick={() => viewDetails(donor.contactId)}>
													<h6 className={css(dbStyleSheet.name, baseStyleSheet.truncateText, baseStyleSheet.fontBold)}>
														{donor.donorName}
													</h6>
												</button>
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{donor.contact ? (
													<EntityChip
														className={css(dbStyleSheet.contact)}
														key={donor.contact.id}
														entity={new Api.ContactViewModel(userSession, donor.contact)}
														readOnly={true}
														onClick={(e: React.MouseEvent<HTMLDivElement>) => onEntityClicked(donor.contactId, e)}
													/>
												) : null}
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>{donor.donationCount}</td>
											<td className={css(dataGridStyles.tableDataCell, dbStyleSheet.amountText)}>
												${numberToCurrencyStringValue(donor.lifetimeDonations)}
											</td>
										</tr>
									);
								})
							: null}

						{isLoading ? (
							<tr className={css(styleSheet.loadingRow)}>
								<td colSpan={8}>
									<LoadingSpinner type='large' />
								</td>
							</tr>
						) : null}
					</tbody>
				</table>

				<Waypoint
					bottomOffset='-200px'
					onEnter={() => (isDonorMode ? donorFilterQuery.fetchNextPage() : donationFilterQuery.fetchNextPage())}
				/>

				{noResultsFound ? (
					<div className={css(dbStyleSheet.noResults)}>
						<EmptySearchIcon />

						<span>We did not find anyone matching your search criteria.</span>
						<span>Please clear your filters and try again.</span>

						<button className={css(baseStyleSheet.ctaButton)} onClick={clearSearch}>
							<span>Clear Search</span>
						</button>
					</div>
				) : null}

				<div className={css(dbStyleSheet.totalRow)}>
					<div className={css(dbStyleSheet.statHeader)}>Donation Stats</div>

					<div className={css(dbStyleSheet.stat)}>
						<h6 className={css(dbStyleSheet.statTitle)}># Donors</h6>
						<span className={css(dbStyleSheet.statValue)}>{summary?.totalDonors || 0}</span>
					</div>

					<div className={css(dbStyleSheet.stat)}>
						<h6 className={css(dbStyleSheet.statTitle)}># Donations</h6>
						<span className={css(dbStyleSheet.statValue)}>{summary?.totalDonations || 0}</span>
					</div>

					<div className={css(dbStyleSheet.stat)}>
						<h6 className={css(dbStyleSheet.statTitle)}>Total</h6>
						<span className={css(dbStyleSheet.totalValue)}>
							${numberToCurrencyStringValue(summary?.totalAmount || 0)}
						</span>
					</div>

					<div>&nbsp;</div>
				</div>
			</div>

			{donationToEdit ? (
				<EditDonationModal
					initialDonation={donationToEdit}
					isOpen={isEditDonationOpen}
					setIsOpen={setIsEditDonationOpen}
					onSave={refreshDonations}
					onCancel={refreshDonations}
				/>
			) : null}

			{contactIdOfDonorToView ? (
				<DonorDetailsFlyout
					isOpen={isViewDonationOpen}
					setIsOpen={setIsViewDonationOpen}
					contactId={contactIdOfDonorToView}
				/>
			) : null}

			{donationToDelete ? (
				<DeleteModal
					type={DeleteType.Donation}
					item={donationToDelete}
					isOpen={isDeleteDonationOpen}
					setIsOpen={setIsDeleteDonationOpen}
					onConfirm={onDeleteConfirmed}
				/>
			) : null}

			<ExportConfirmationModalV2
				// @ts-ignore
				mutationResult={exportDonationsMutation}
				modalProps={{
					isOpen: isDonorMode ? !exportDonationsByDonorsMutation.isIdle : !exportDonationsMutation.isIdle,
					onRequestClose: () =>
						isDonorMode ? exportDonationsByDonorsMutation.reset() : exportDonationsMutation.reset(),
				}}
				subTitle={
					<>
						<p className={css(dbStyleSheet.noMargin)}>
							The fields we&apos;ll export include: Donor Name, Contact Id, Lifetime Donations, Most Recent Donation
							Amount, Most Recent Donation Date, Most Recent Donation Campaign Name
						</p>
					</>
				}
			/>

			<FabContext appearance={{ hidden: true }} />
		</div>
	);
}
