import { css } from 'aphrodite';
import { produce } from 'immer';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { v4 as uuid } from 'uuid';
import { FormFieldType, IFormField, IRange, ISatisfactionSurvey } from '../../../extViewmodels';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../models';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { DateRangePickerModal } from '../../components/DateRangePicker';
import { RadioButton } from '../../components/RadioButton';
import { TextArea } from '../../components/TextArea';
import { TextInputFormField } from '../../components/TextInputFormField';
import { Toggle } from '../../components/Toggle';
import { StarRating } from '../../components/surveys/StarRating';
import { TrashIcon } from '../../components/svgs/icons/TrashIcon';
import { darkGrayFontColor, grayIconFill, success } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { useEditSurveyContext } from './context';
import { styleSheet } from './styles';

enum BasicInfoInputIds {
	CompanyName = 'edit-survey-company-name-input',
	IndefDateRange = 'edit-survey-indef-date-range-input',
	Intro = 'edit-survey-intro-input',
	Name = 'edit-survey-name-input',
	SpecificDateRange = 'edit-survey-specific-date-range-input',
}

export const BasicSurveyInfo: React.FC = () => {
	const { survey, setSurvey } = useEditSurveyContext<ISatisfactionSurvey>();
	const [surveyName, setSurveyName] = React.useState<string>(survey?.name || '');
	const [companyName, setCompanyName] = React.useState<string>(survey?.companyDisplayName || '');
	const [intro, setIntro] = React.useState<string>(survey?.intro || '');
	const isArchived = !!survey?.archivedDate;

	// #region Date range modal
	const [dateRangeModalIsOpen, setDateRangeModalIsOpen] = React.useState<boolean>(false);
	const onDateRangeModalRequestClose = React.useCallback(
		(result?: IRange<Date>, cancel?: boolean) => {
			if (!cancel && result) {
				const nextSurvey = produce(survey, draftSurvey => {
					// @ts-ignore
					draftSurvey.startDate = result.start.toISOString();
					// @ts-ignore
					draftSurvey.expirationDate = result.end.toISOString();
				});
				setSurvey(nextSurvey);
			}
			setDateRangeModalIsOpen(false);
		},
		[survey, setSurvey]
	);
	const onEditDateRangeClicked = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
		setDateRangeModalIsOpen(true);
		e.stopPropagation();
		e.preventDefault();
	}, []);
	// #endregion

	// #region Input callbacks
	const onInputBlur = React.useCallback(() => {
		const nextSurvey = produce(survey, draftSurvey => {
			draftSurvey.companyDisplayName = companyName?.trim() ?? null;
			draftSurvey.name = surveyName?.trim() ?? '';
			draftSurvey.intro = intro?.trim() ?? '';
		});
		unstable_batchedUpdates(() => {
			// @ts-ignore
			setSurveyName(nextSurvey.name);
			// @ts-ignore
			setCompanyName(nextSurvey.companyDisplayName);
			// @ts-ignore
			setIntro(nextSurvey.intro);
			setSurvey(nextSurvey);
		});
	}, [surveyName, companyName, survey, setSurvey, intro]);

	const onInputChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		switch (e.target.id) {
			case BasicInfoInputIds.Name: {
				setSurveyName(e.target.value);
				break;
			}
			case BasicInfoInputIds.CompanyName: {
				setCompanyName(e.target.value);
				break;
			}
			case BasicInfoInputIds.Intro: {
				setIntro(e.target.value);
				break;
			}
			default: {
				break;
			}
		}
	}, []);

	const onDateRangeRadioButtonChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.id === BasicInfoInputIds.IndefDateRange && e.target.checked) {
				const nextSurvey = produce(survey, draftSurvey => {
					draftSurvey.startDate = moment().toISOString();
					// @ts-ignore
					draftSurvey.expirationDate = null;
				});
				setSurvey(nextSurvey);
			}

			if (e.target.id === BasicInfoInputIds.SpecificDateRange && e.target.checked) {
				setDateRangeModalIsOpen(true);
			}
		},
		[setSurvey, survey]
	);
	// #endregion

	const hasDateRange = !!survey?.startDate && !!survey?.expirationDate;
	return (
		<section className={css(styleSheet.section)}>
			<h3 className={css(styleSheet.sectionTitle)}>Basic Survey Info</h3>
			<div className={css(styleSheet.fieldGroup)}>
				<TextInputFormField
					disabled={isArchived}
					inputId={BasicInfoInputIds.Name}
					label={
						<span>
							Survey Name
							<span className={css(baseStyleSheet.required)}>*</span>
						</span>
					}
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					type='text'
					value={surveyName || ''}
				/>
				<TextInputFormField
					disabled={isArchived}
					inputId={BasicInfoInputIds.CompanyName}
					label='Company Name'
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					type='text'
					value={companyName || ''}
				/>
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<div className={css(styleSheet.label)}>
					<span>
						Survey Intro
						<span className={css(baseStyleSheet.required)}>*</span>
					</span>
				</div>
				<TextArea
					disabled={isArchived}
					inputClassName={css(styleSheet.introTextArea)}
					inputId={BasicInfoInputIds.Intro}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					value={intro || ''}
				/>
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<div className={css(styleSheet.label)}>Survey Date Range</div>
				<div className={css(styleSheet.radioButtons)}>
					<RadioButton
						// @ts-ignore
						checked={survey?.startDate && !survey?.expirationDate}
						disabled={isArchived}
						id={BasicInfoInputIds.IndefDateRange}
						onChange={onDateRangeRadioButtonChanged}
					>
						<span>Survey is available indefinitely into the future</span>
					</RadioButton>
					<RadioButton
						checked={hasDateRange}
						disabled={isArchived}
						id={BasicInfoInputIds.SpecificDateRange}
						onChange={onDateRangeRadioButtonChanged}
					>
						<span>
							{`Survey is only available for a specific date range${hasDateRange ? ':' : ''}`}
							&nbsp;
						</span>
						{hasDateRange && (
							<button className={css(baseStyleSheet.brandLink)} disabled={isArchived} onClick={onEditDateRangeClicked}>
								<span>
									{`${moment(survey.startDate).format('MM/DD/yyyy')} - ${moment(survey.expirationDate).format(
										'MM/DD/yyyy'
									)}`}
								</span>
							</button>
						)}
					</RadioButton>
				</div>
				<DateRangePickerModal
					modalProps={{
						isOpen: dateRangeModalIsOpen,
						onRequestClose: onDateRangeModalRequestClose,
					}}
					range={
						hasDateRange
							? {
									// @ts-ignore
									end: new Date(survey.expirationDate),
									// @ts-ignore
									start: new Date(survey.startDate),
								}
							: undefined
					}
				/>
			</div>
		</section>
	);
};

enum ReviewRequestInputIds {
	Link = 'review-request-link-input',
	LinkType = 'review-request-link-type-input',
	Message = 'review-request-message-input',
}

const KnownReviewSites = {
	'Better Business Bureau': [/\.(bbb)\./gi],
	G2: [/\.?(g2)\./gi],
	Google: [/\.?(google)\./gi],
	Trustpilot: [/\.?(trustpilot)\./gi],
	Yelp: [/\.?(yelp)\./gi],
};

const LIMIT = 5;

export const ReviewRequestOptionsBase: React.FC<IImpersonationContextComponentProps> = props => {
	const { survey, setSurvey } = useEditSurveyContext();
	const userSession = useUserSession();

	const satisfactionSurvey = survey as ISatisfactionSurvey;
	const defaultReviewLink = (
		props.impersonationContext?.account?.features?.surveys || userSession.account.features?.surveys
	)?.defaultReviewLink;
	const [linkStringValue, setLinkStringValue] = React.useState<string>(
		satisfactionSurvey?.reviewRequestSettings?.reviewUrl || (!survey?.id ? defaultReviewLink : null) || ''
	);
	const [linkTypeStringValue, setLinkTypeStringValue] = React.useState<string>(
		satisfactionSurvey?.reviewRequestSettings?.reviewType || ''
	);
	const [requestMessage, setRequestMessage] = React.useState<string>(
		satisfactionSurvey?.reviewRequestSettings?.reviewRequest || ''
	);
	const isArchived = !!survey?.archivedDate;

	const onToggleCheckChanged = React.useCallback(
		(checked: boolean) => {
			const nextSurvey = produce(satisfactionSurvey, draftSurvey => {
				draftSurvey.reviewRequestSettings.isEnabled = checked;
				draftSurvey?.customForm?.fields?.forEach(field => {
					const fieldMetaData = draftSurvey.customFormMetaData.find(metaData => metaData.fieldId === field.id);
					const newRatings = Array.from({ length: 3 }, (_, ratingIndex) => ratingIndex + 1);
					// find the metadata and set to 3;
					if (!fieldMetaData) {
						draftSurvey.customFormMetaData.push({
							fieldName: field.id,
							ratings: newRatings,
						});
					} else {
						fieldMetaData.ratings = newRatings;
					}
				});
			});
			setSurvey(nextSurvey);
		},
		[satisfactionSurvey, setSurvey]
	);

	const onInputBlur = React.useCallback(() => {
		const nextSurvey = produce(satisfactionSurvey, draftSurvey => {
			draftSurvey.reviewRequestSettings.reviewRequest = requestMessage?.trim() ?? null;

			draftSurvey.reviewRequestSettings.reviewUrl = linkStringValue?.trim() ?? null;

			draftSurvey.reviewRequestSettings.reviewType = linkTypeStringValue?.trim() ?? null;

			if (draftSurvey.reviewRequestSettings.reviewUrl) {
				if (!/^http[s]?:\/\//gim.test(draftSurvey.reviewRequestSettings.reviewUrl)) {
					draftSurvey.reviewRequestSettings.reviewUrl = `https://${draftSurvey.reviewRequestSettings.reviewUrl}`;
				}

				if (!draftSurvey.reviewRequestSettings.reviewType) {
					const match = Object.entries(KnownReviewSites).find(entry =>
						entry[1].some(x => x.test(draftSurvey.reviewRequestSettings.reviewUrl))
					);
					if (match) {
						draftSurvey.reviewRequestSettings.reviewType = match[0];
					}
				}
			}
		});
		unstable_batchedUpdates(() => {
			setSurvey(nextSurvey);
			setRequestMessage(nextSurvey.reviewRequestSettings.reviewRequest);
			setLinkStringValue(nextSurvey.reviewRequestSettings.reviewUrl);
			setLinkTypeStringValue(nextSurvey.reviewRequestSettings.reviewType);
		});
	}, [linkStringValue, requestMessage, satisfactionSurvey, setSurvey, linkTypeStringValue]);

	const onInputChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.target.id === ReviewRequestInputIds.Link) {
			setLinkStringValue(e.target.value);
		}

		if (e.target.id === ReviewRequestInputIds.LinkType) {
			setLinkTypeStringValue(e.target.value);
		}

		if (e.target.id === ReviewRequestInputIds.Message) {
			setRequestMessage(e.target.value);
		}
	}, []);

	const onStarRatingOptionCheckChanged = React.useCallback(
		(value: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const indexOfMatch = satisfactionSurvey?.reviewRequestSettings?.ratings?.findIndex(x => x === value);

			if (indexOfMatch > -1 && !e.target.checked) {
				const nextRatings: number[] = (satisfactionSurvey.reviewRequestSettings.ratings || []).slice();
				nextRatings.splice(indexOfMatch, 1);
				const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
					draftSurvey.reviewRequestSettings.ratings = nextRatings;
				});
				setSurvey(nextSurvey);
				return;
			}
			if (e.target.checked) {
				const nextRatings: number[] = (satisfactionSurvey.reviewRequestSettings.ratings || []).slice();
				nextRatings.push(value);
				const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
					draftSurvey.reviewRequestSettings.ratings = nextRatings;
				});
				setSurvey(nextSurvey);
			}
		},
		[satisfactionSurvey, setSurvey]
	);

	if (survey?._type !== 'SatisfactionSurvey') {
		return null;
	}

	return (
		<div className={css(styleSheet.section)}>
			<div>
				<section className={css(styleSheet.layoutFlexRowSpaceBetween)}>
					<h3 className={css(styleSheet.sectionTitle)}>Ask for a Review</h3>
					<Toggle
						checkedColor={success}
						disabled={isArchived}
						id='edit-survey-review-request-toggle'
						isOn={satisfactionSurvey?.reviewRequestSettings?.isEnabled}
						onToggleCheckChanged={onToggleCheckChanged}
						text={satisfactionSurvey?.reviewRequestSettings?.isEnabled ? 'Enabled' : 'Disabled'}
						textStyles={[styleSheet.toggleText]}
						uncheckedColor={grayIconFill}
					/>
				</section>
			</div>
			<div className={css(styleSheet.fieldGroup)}>
				<TextInputFormField
					disabled={isArchived}
					inputId={ReviewRequestInputIds.Link}
					label='Link to the review page (Google, Yelp, etc.)'
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					type='text'
					value={linkStringValue || ''}
				/>
				<TextInputFormField
					disabled={isArchived}
					inputId={ReviewRequestInputIds.LinkType}
					label='What type of review is it above?'
					labelStyles={[styleSheet.label]}
					onBlur={onInputBlur}
					onChange={onInputChanged}
					styles={[styleSheet.reviewRequestLinkTypeInput]}
					type='text'
					value={linkTypeStringValue || ''}
				/>
			</div>
			<div
				className={css(
					baseStyleSheet.verticalStack,
					!satisfactionSurvey?.reviewRequestSettings?.isEnabled ? styleSheet.disabled : null
				)}
			>
				<div className={css(styleSheet.label)}>Ask to leave a review when someone gives you the following ratings:</div>
				<div className={css(styleSheet.fieldGroup)}>
					{Array.from({ length: LIMIT }).map((_, i) => {
						const count = LIMIT - i;
						return (
							<Checkbox
								checked={satisfactionSurvey?.reviewRequestSettings?.ratings?.indexOf(count) > -1}
								disabled={isArchived}
								id={`edit-survey-review-request-${count}stars`}
								key={`edit-survey-review-request-${count}stars`}
								onChange={onStarRatingOptionCheckChanged(count)}
							>
								<div className={css(styleSheet.starRating)}>
									<span>{`${count}/${LIMIT}`}</span>
									<StarRating fillColor='#F3CE0D' readonly={true} total={LIMIT} value={count} />
								</div>
							</Checkbox>
						);
					})}
				</div>
				<div className={css(styleSheet.divider)} />
				<div className={css(styleSheet.fieldGroup)}>
					<label className={css(styleSheet.label)}>Request shown after a positive rating is left:</label>
					<TextArea
						disabled={isArchived}
						inputClassName={css(styleSheet.reviewRequestMessage)}
						inputId={ReviewRequestInputIds.Message}
						onBlur={onInputBlur}
						onChange={onInputChanged}
						value={requestMessage || ''}
					/>
				</div>
			</div>
		</div>
	);
};

export const ReviewRequestOptions = inject(ImpersonationContextKey)(ReviewRequestOptionsBase);

const AdditionalQuestionsBase: React.FC<IImpersonationContextComponentProps> = () => {
	const { survey, setSurvey } = useEditSurveyContext();
	const satisfactionSurvey = survey as ISatisfactionSurvey;

	const onToggleCheckChanged = React.useCallback(
		(checked: boolean) => {
			const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
				draftSurvey.customFormIsDisabled = checked;
			});
			setSurvey(nextSurvey);
		},
		[satisfactionSurvey, setSurvey]
	);
	// handle remove question
	const handleRemoveQuestion = (index: number) => {
		const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
			const newQuestions = [...draftSurvey.customForm.fields];
			newQuestions.splice(index, 1);
			draftSurvey.customForm.fields = newQuestions;
		});
		setSurvey(nextSurvey);
	};

	const isAdditionalQuestionsDisabled = satisfactionSurvey.customFormIsDisabled;

	return (
		<section className={css(styleSheet.bottomSection)}>
			<div className={css(styleSheet.section, styleSheet.sectionFullWidth)}>
				<section className={css(bs.flex, bs.flexRow, bs.flexBetween)}>
					<h3 className={css(styleSheet.sectionTitle)}>Additional Questions</h3>
					<Toggle
						checkedColor={success}
						id='edit-survey-additionalQuestions-toggle'
						isOn={!isAdditionalQuestionsDisabled}
						onToggleCheckChanged={() => onToggleCheckChanged(!isAdditionalQuestionsDisabled)}
						text={!isAdditionalQuestionsDisabled ? 'Enabled' : 'Disabled'}
						textStyles={[styleSheet.toggleText]}
						uncheckedColor={grayIconFill}
					/>
				</section>
				<div>
					{satisfactionSurvey?.customForm?.fields?.map((field, index) => {
						let fieldMetaData = satisfactionSurvey?.customFormMetaData?.find(metaData => metaData.fieldId === field.id);
						// if metadata doesn't exist, set it
						if (!fieldMetaData) {
							fieldMetaData = {
								fieldId: field.id,
								isConditional: false,
								ratings: [1, 2, 3],
							};
						}

						return (
							<div className={css(styleSheet.questionWrap)} key={`${field.id}-question-${index}`}>
								<TextInputFormField
									disabled={isAdditionalQuestionsDisabled}
									inputId={`edit-survey-${field.id}question-${index}-input`}
									label={`Question ${index + 1}`}
									labelStyles={[styleSheet.label]}
									placeholder={
										index === 0 ? 'Example: Do you have any private feedback for us?' : `Question ${index + 1}`
									}
									onChange={e => {
										const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
											const newQuestions = [...draftSurvey.customForm.fields];
											newQuestions[index].label = e.target.value;
											draftSurvey.customForm.fields = newQuestions;
										});
										setSurvey(nextSurvey);
									}}
									type='text'
									value={satisfactionSurvey.customForm.fields[index].label}
									styles={[styleSheet.questionInput]}
								/>
								{satisfactionSurvey?.customForm?.fields.length !== 1 ? (
									<button
										type='button'
										className={css(styleSheet.deleteButton)}
										onClick={() => handleRemoveQuestion(index)}
									>
										Remove additional question
										<TrashIcon fillColor={darkGrayFontColor} />
									</button>
								) : null}
								{!satisfactionSurvey.reviewRequestSettings.isEnabled ? (
									<Checkbox
										className={css(styleSheet.checkboxLabel)}
										checked={fieldMetaData?.isConditional}
										disabled={isAdditionalQuestionsDisabled}
										id={`edit-survey-ask-question-${index}-checkbox`}
										onChange={() => {
											// update checkbox value
											const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
												// find the metaData and set to 3; if it doesn't exist, add it
												const fieldMD = draftSurvey.customFormMetaData.find(x => x.fieldId === field.id);
												if (fieldMD) {
													fieldMD.isConditional = !fieldMetaData.isConditional;
												} else {
													draftSurvey.customFormMetaData = [
														{
															fieldId: field.id,
															isConditional: true,
															ratings: Array.from({ length: 3 }, (_, ratingIndex) => ratingIndex + 1),
														},
													];
												}
											});
											setSurvey(nextSurvey);
										}}
									>
										<div className={css(styleSheet.checkboxText)}>
											<div>Ask this question if the rating is </div>
											<TextInputFormField
												disabled={isAdditionalQuestionsDisabled || !fieldMetaData.isConditional}
												value={fieldMetaData?.ratings?.[fieldMetaData?.ratings?.length - 1]}
												type='number'
												className={css(styleSheet.thresholdInput)}
												onKeyDown={e => {
													// prevent typing of decimal points
													if (
														e.key === '.' ||
														e.key === '-' ||
														e.key === '+' ||
														// @ts-ignore
														(e.key === 'ArrowDown' && Number(e.target.value) <= 1) ||
														// @ts-ignore
														(e.key === 'ArrowUp' && Number(e.target.value) >= 5)
													) {
														e.preventDefault();
													}
												}}
												onBlur={e => {
													// if target value is not a number or is less than 1, set it to 1
													if (Number(e.target.value) < 1) {
														const _nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
															draftSurvey.customFormMetaData.find(metaData => metaData.fieldId === field.id).ratings = [
																1,
															];
														});
														setSurvey(_nextSurvey);
														return;
													}
													// if target value is greater than 5, set it to 5
													if (Number(e.target.value) > 5) {
														const _nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
															draftSurvey.customFormMetaData.find(metaData => metaData.fieldId === field.id).ratings = [
																1, 2, 3, 4, 5,
															];
														});
														setSurvey(_nextSurvey);
														return;
													}
												}}
												onChange={e => {
													// update threshold value
													const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
														const customFormFieldMeta = draftSurvey.customFormMetaData.find(
															metaData => metaData.fieldId === field.id
														);
														if (customFormFieldMeta) {
															customFormFieldMeta.ratings = Array.from(
																{ length: Number(e.target.value) },
																(_, ratingIndex) => ratingIndex + 1
															);
														} else {
															// add new metadata if it doesn't exist
															draftSurvey.customFormMetaData = [
																...draftSurvey.customFormMetaData,
																{
																	fieldId: field.id,
																	ratings: Array.from(
																		{ length: Number(e.target.value) },
																		(_, ratingIndex) => ratingIndex + 1
																	),
																},
															];
														}
													});
													setSurvey(nextSurvey);
												}}
											/>{' '}
											<div>
												star
												{fieldMetaData?.ratings?.length > 1 ? 's or below' : ''}
											</div>
										</div>
									</Checkbox>
								) : null}
							</div>
						);
					})}
				</div>

				<div className={css(bs.flex, bs.justifyEnd)}>
					{satisfactionSurvey?.customForm?.fields?.length < LIMIT ? (
						<Button
							kind='custom'
							disabled={isAdditionalQuestionsDisabled}
							className={css(styleSheet.addQuestionButton)}
							onClick={() => {
								const nextSurvey: ISatisfactionSurvey = produce(satisfactionSurvey, draftSurvey => {
									const fieldId = uuid();
									const field: IFormField<string> = {
										fieldType: FormFieldType.String,
										id: fieldId,
										label: '',
										isHidden: false,
										isOptional: true,
									};
									const addNewSurvey = [...draftSurvey.customForm.fields, field];
									draftSurvey.customForm.fields = addNewSurvey;
									draftSurvey.customFormMetaData = [
										...draftSurvey.customFormMetaData,
										{
											fieldId,
											isConditional: false,
											ratings: [1, 2, 3],
										},
									];
								});
								setSurvey(nextSurvey);
							}}
							label='+ Add Another Question'
						/>
					) : null}
				</div>
			</div>
		</section>
	);
};

export const AdditionalQuestions = inject(ImpersonationContextKey)(AdditionalQuestionsBase);
