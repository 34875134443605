import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import { useLambda } from '../../../models/hooks/useLambda';
import textingCampaignAutomationTriggerGraphic from '../../assets/texting-campaign-automation-trigger-graphic.svg';
import { asModalComponent } from '../../components/Modal';
import {
	AutomationNewLeadTriggerSettings,
	AutomationTagTriggerSettings,
} from '../../components/automation/AutomationTriggerCard/presentation';
import { BirthdayAutomationTriggerGraphic } from '../../components/svgs/graphics/BirthdayAutomationTriggerGraphic';
import { EventRegistrationTriggerGraphic } from '../../components/svgs/graphics/EventRegistrationTriggerGraphic';
import { ManualAutomationTriggerGraphic } from '../../components/svgs/graphics/ManualAutomationTriggerGraphic';
import { MeetingReminderTriggerGraphic } from '../../components/svgs/graphics/MeetingReminderTriggerGraphic';
import { NewClientAutomationTriggerGraphic } from '../../components/svgs/graphics/NewClientAutomationTriggerGraphic';
import { NewCommercialClientAutomationTriggerGraphic } from '../../components/svgs/graphics/NewCommercialClientAutomationTriggerGraphic';
import { NewDonorAutomationTriggerGraphic } from '../../components/svgs/graphics/NewDonorAutomationGraphic';
import { NewLeadsAutomationTriggerGraphic } from '../../components/svgs/graphics/NewLeadsAutomationTriggerGraphic';
import { NewPersonalClientAutomationTriggerGraphic } from '../../components/svgs/graphics/NewPersonalClientAutomationTriggerGraphic';
import { RenewalAutomationTriggerGraphic } from '../../components/svgs/graphics/RenewalAutomationTriggerGraphic';
import { TagAutomationTriggerGraphic } from '../../components/svgs/graphics/TagAutomationTriggerGraphic';
import { Turning65AutomationTriggerGraphic } from '../../components/svgs/graphics/Turning65AutomationTriggerGraphic';
import { TurningXXTriggerGraphic } from '../../components/svgs/graphics/TurningXXGraphic';
import { baseStyleSheet } from '../../styles/styles';
import { styleSheet } from './styles';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	styles?: StyleDeclarationValue[];
	trigger?: Api.IAutomationTrigger;
	triggerName: string;
}

export const NewAutomationTemplateCard: React.FC<IProps> = ({
	trigger,
	className = '',
	styles = [],
	triggerName,
	...restProps
}) => {
	let Graphic: JSX.Element | null = null;
	switch (trigger?._type) {
		case Api.AutomationTriggerType.Texting: {
			Graphic = <img src={textingCampaignAutomationTriggerGraphic} />;
			break;
		}
		case Api.AutomationTriggerType.Tag: {
			Graphic = <TagAutomationTriggerGraphic />;
			break;
		}
		case Api.AutomationTriggerType.NewLead: {
			Graphic = <NewLeadsAutomationTriggerGraphic />;
			break;
		}
		case Api.AutomationTriggerType.Meeting: {
			Graphic = <MeetingReminderTriggerGraphic />;
			break;
		}
		case Api.AutomationTriggerType.EventRegistration: {
			Graphic = <EventRegistrationTriggerGraphic />;
			break;
		}
		case Api.AutomationTriggerType.NewDonor: {
			Graphic = <NewDonorAutomationTriggerGraphic />;
			break;
		}
		case Api.AutomationTriggerType.NewClient: {
			const t = trigger as Api.INewClientAutomationTrigger;
			if (t.clientType?.[0] === Api.NewClientType.Any) {
				Graphic = <NewClientAutomationTriggerGraphic />;
			} else if (t.clientType?.[0] === Api.NewClientType.Commercial) {
				Graphic = <NewCommercialClientAutomationTriggerGraphic />;
			} else if (t.clientType?.[0] === Api.NewClientType.Personal) {
				Graphic = <NewPersonalClientAutomationTriggerGraphic />;
			}
			break;
		}
		case Api.AutomationTriggerType.ResourceSelector: {
			const t = trigger as Api.IResourceSelectorAutomationTrigger;

			switch (t.resourceSelector) {
				case Api.ResourceSelectorId.HappyBirthday: {
					Graphic = <BirthdayAutomationTriggerGraphic />;
					break;
				}
				case Api.ResourceSelectorId.PolicyRenew:
				case Api.ResourceSelectorId.FinancialReview: {
					Graphic = <RenewalAutomationTriggerGraphic />;
					break;
				}
				case Api.ResourceSelectorId.Turning72:
				case Api.ResourceSelectorId.Turning73:
				case Api.ResourceSelectorId.Turning65: {
					Graphic = <Turning65AutomationTriggerGraphic />;
					break;
				}
				case Api.ResourceSelectorId.Custom1:
				case Api.ResourceSelectorId.Custom2:
				case Api.ResourceSelectorId.Custom3:
				case Api.ResourceSelectorId.Custom4:
				case Api.ResourceSelectorId.Custom5:
				case Api.ResourceSelectorId.Custom6:
				case Api.ResourceSelectorId.Custom7:
				case Api.ResourceSelectorId.Custom8:
				case Api.ResourceSelectorId.Custom9:
				case Api.ResourceSelectorId.Custom10: {
					Graphic = <MeetingReminderTriggerGraphic />;
					break;
				}
				case Api.ResourceSelectorId.TurningXX: {
					Graphic = <TurningXXTriggerGraphic />;
					break;
				}
				default: {
					break;
				}
			}
			break;
		}
		default: {
			break;
		}
	}

	if (!Graphic) {
		Graphic = <ManualAutomationTriggerGraphic />;
	}
	const cardTitle =
		(triggerName && trigger?.resourceSelector?.startsWith('Custom')) ||
		trigger?.resourceSelector === Api.ResourceSelectorId.TurningXX
			? `${triggerName} Automation`
			: Api.VmUtils.Automations.triggers.getAutomationTriggerTitle(trigger);
	return (
		<button {...restProps} className={`${className} ${css(styleSheet.card, ...styles)}`}>
			<figure tabIndex={-1}>{Graphic}</figure>
			<span tabIndex={-1}>{cardTitle}</span>
		</button>
	);
};

interface IAutomationTemplateSetupSettings<T extends Api.IAutomationTrigger = Api.IAutomationTrigger, TResult = any>
	extends IModalContext<TResult> {
	template?: Api.AutomationTemplateViewModel;
	trigger: T;
}

export const NewTagAutomationTemplateSetupSettings: React.FC<
	IAutomationTemplateSetupSettings<Api.ITagAutomationTrigger, string>
> = props => {
	const { trigger, template, parentModal } = props;
	// @ts-ignore
	const [tag, setTag] = useLambda<string>(null);

	const onCreateClicked = React.useCallback(() => {
		parentModal?.onRequestClose(tag, false);
	}, [tag, parentModal]);

	const onCancelClicked = React.useCallback(() => {
		// @ts-ignore
		parentModal?.onRequestClose(null, true);
	}, [parentModal]);
	return (
		<div className={css(styleSheet.triggerSettings)}>
			<AutomationTagTriggerSettings automationTemplate={template} onTagSelected={setTag} trigger={trigger} />
			<div className={css(baseStyleSheet.horizontalStack)}>
				<button className={css(baseStyleSheet.ctaButton)} disabled={!tag} onClick={onCreateClicked}>
					<span>Create Automation</span>
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancelClicked}>
					<span>Cancel</span>
				</button>
			</div>
		</div>
	);
};

const NewTagAutomationTemplateSetupSettingsWithContext = inject(ModalChildComponentContextKey)(
	NewTagAutomationTemplateSetupSettings
);
export const NewTagAutomationTemplateSetupSettingsModal = asModalComponent(
	NewTagAutomationTemplateSetupSettingsWithContext,
	{
		className: css(styleSheet.triggerSettingsModal),
		shouldCloseOnOverlayClick: false,
		useDefaultHeader: true,
	}
);

export const NewLeadAutomationTemplateSetupSettings: React.FC<
	IAutomationTemplateSetupSettings<Api.INewLeadAutomationTrigger, Api.INewLeadAutomationTrigger>
> = props => {
	const { trigger, template, parentModal } = props;
	const [triggerToSet, setTriggerToSet] = useLambda<Api.INewLeadAutomationTrigger>(trigger);

	const onCreateClicked = React.useCallback(() => {
		parentModal?.onRequestClose(triggerToSet, false);
	}, [triggerToSet, parentModal]);

	const onCancelClicked = React.useCallback(() => {
		// @ts-ignore
		parentModal?.onRequestClose(null, true);
	}, [parentModal]);

	const onRequestSetTrigger = React.useCallback((t: Api.INewLeadAutomationTrigger) => {
		setTriggerToSet(t);
		return Promise.resolve();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={css(styleSheet.triggerSettings)}>
			<AutomationNewLeadTriggerSettings
				automationTemplate={template}
				onRequestSetTrigger={onRequestSetTrigger}
				trigger={triggerToSet}
			/>
			<div className={css(baseStyleSheet.horizontalStack)}>
				<button
					className={css(baseStyleSheet.ctaButton)}
					// @ts-ignore
					disabled={!(triggerToSet?.emailScannerIds?.length > 0)}
					onClick={onCreateClicked}
				>
					<span>Create Automation</span>
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onCancelClicked}>
					<span>Cancel</span>
				</button>
			</div>
		</div>
	);
};

const NewLeadAutomationTemplateSetupSettingsWithContext = inject(ModalChildComponentContextKey)(
	NewLeadAutomationTemplateSetupSettings
);
export const NewLeadAutomationTemplateSetupSettingsModal = asModalComponent(
	NewLeadAutomationTemplateSetupSettingsWithContext,
	{
		className: css(styleSheet.triggerSettingsModal),
		shouldCloseOnOverlayClick: false,
		useDefaultHeader: true,
	}
);
