import * as Api from '@ViewModels';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import { BlogPostRequest } from '../../../../models/Blogs';
import {
	createContentStateWithHtmlStringValue,
	createRichContentEditorStateWithText,
} from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useContextGuard } from '../../../../models/hooks/useContextGuard';
import { BlogPostContext } from '../blogPostContext';

export enum ImageStage {
	NotLoaded,
	Loading,
	Loaded,
	Removed,
}
interface IState {
	title: string;
	blogGenerationJobId: string;
	blogId: string;
	content: Api.IRichContentEditorState;
	selectedImage: Api.IFileAttachmentWithURL | null;
	showingAiContentGenWizard: boolean;
	fileInputModalOpen: boolean;
	blogGenerated: boolean;
	showScheduleAction: boolean;
	sendFromUser: Partial<Api.IUser>;
	status?: Api.BlogStatus;
	scheduledSendDate?: Date;
	showConfirmationModal?: boolean;
	imageState: ImageStage;
	imageSuggestingLoading: boolean;
	suggestedImage: boolean;
}

export enum REDUCER_ACTION_KEYS {
	SET_TITLE = 'SET_TITLE',
	IMAGE_ATTACH_SUCCESS = 'IMAGE_ATTACH_SUCCESS',
	BLOG_GENERATION_START = 'BLOG_GENERATION_START',
	IMAGE_SELECTED = 'IMAGE_SELECTED',
	UPDATE_WITH_BLOG_POST = 'UPDATE_WITH_BLOG_POST',
	RESET_BLOG_POST = 'RESET_BLOG_POST',
	SYSTEM_JOB_SUCCESS = 'SYSTEM_JOB_SUCCESS',
	BLOG_GENERATION_FAILED = 'BLOG_GENERATION_FAILED',
	TOGGLE_FILE_INPUT_MODAL = 'TOGGLE_FILE_INPUT_MODAL',
	SET_CONTENT = 'SET_CONTENT',
	AI_WIZARD_CLOSED = 'AI_WIZARD_CLOSED',
	SET_SEND_ON_BEHALF_OF_USER = 'SET_SEND_ON_BEHALF_OF_USER',
	SET_STATE = 'SET_STATE',
	DELETE_BLOG_POST = 'DELETE_BLOG_POST',
	SHOWINITIAL_AI_WIZARD = 'SHOWINITIAL_AI_WIZARD',
	IMAGE_SUCCESSFULLY_UPLOADED = 'IMAGE_SUCCESSFULLY_UPLOADED',
	GENERATING_CONTENT = 'GENERATING_CONTENT',
}

export type TAction =
	| { type: REDUCER_ACTION_KEYS.SET_TITLE; payload: { title: string } }
	| { type: REDUCER_ACTION_KEYS.IMAGE_ATTACH_SUCCESS; payload: { image: Api.IFileAttachmentWithURL } }
	| { type: REDUCER_ACTION_KEYS.BLOG_GENERATION_START; payload: { id: string } }
	| { type: REDUCER_ACTION_KEYS.IMAGE_SELECTED; payload: { image: Api.IFileAttachmentWithURL } }
	| {
			type: REDUCER_ACTION_KEYS.UPDATE_WITH_BLOG_POST;
			payload: Partial<Api.IBlogPost>;
	  }
	| { type: REDUCER_ACTION_KEYS.RESET_BLOG_POST }
	| {
			type: REDUCER_ACTION_KEYS.SYSTEM_JOB_SUCCESS;
			payload: Partial<Api.IBlogPost>;
	  }
	| { type: REDUCER_ACTION_KEYS.BLOG_GENERATION_FAILED }
	| { type: REDUCER_ACTION_KEYS.TOGGLE_FILE_INPUT_MODAL; payload: { open: boolean } }
	| { type: REDUCER_ACTION_KEYS.SET_CONTENT; payload: { content: Api.IRichContentEditorState } }
	| { type: REDUCER_ACTION_KEYS.AI_WIZARD_CLOSED }
	| { type: REDUCER_ACTION_KEYS.SET_SEND_ON_BEHALF_OF_USER; payload: Partial<Api.IUser> }
	| {
			type: REDUCER_ACTION_KEYS.SET_STATE;
			payload: Partial<IState>;
	  }
	| { type: REDUCER_ACTION_KEYS.DELETE_BLOG_POST; payload: { value: boolean } }
	| { type: REDUCER_ACTION_KEYS.SHOWINITIAL_AI_WIZARD; payload: { value: boolean } }
	| { type: REDUCER_ACTION_KEYS.IMAGE_SUCCESSFULLY_UPLOADED; payload: Api.IFileAttachmentWithURL }
	| {
			type: REDUCER_ACTION_KEYS.GENERATING_CONTENT;
			payload: {
				imageSuggestingLoading: boolean;
				imageState: ImageStage;
			};
	  };

function reducer(state: IState, action: TAction): IState {
	const updateWithBlogPost = (s: IState, post: Partial<Api.IBlogPost>) => {
		const contentString = post.content.document.replace(
			new RegExp(`</?${Api.RawRichTextContentDocumentRootElementName}>`, 'gim'),
			''
		);
		const newState: IState = {
			...s,
			title: post.title,
			content: createContentStateWithHtmlStringValue(contentString),
			blogId: post.id,
			status: post?.status,
		};
		if (post.mainImage) {
			newState.selectedImage = post.mainImage;
		}
		return newState;
	};

	switch (action.type) {
		case REDUCER_ACTION_KEYS.SET_TITLE:
			return {
				...state,
				title: action.payload.title,
			};
		case REDUCER_ACTION_KEYS.IMAGE_ATTACH_SUCCESS: {
			return {
				...state,
				selectedImage: action.payload.image,
				fileInputModalOpen: false,
			};
		}
		case REDUCER_ACTION_KEYS.BLOG_GENERATION_START: {
			return {
				...state,
				blogGenerated: false,
				blogGenerationJobId: action.payload.id,
			};
		}
		case REDUCER_ACTION_KEYS.IMAGE_SELECTED: {
			return {
				...state,
				selectedImage: action.payload.image,
			};
		}
		case REDUCER_ACTION_KEYS.UPDATE_WITH_BLOG_POST: {
			return updateWithBlogPost(state, action.payload);
		}
		case REDUCER_ACTION_KEYS.RESET_BLOG_POST: {
			return {
				...state,
				title: '',
				blogGenerationJobId: '',
				blogId: '',
				content: createRichContentEditorStateWithText(''),
			};
		}
		case REDUCER_ACTION_KEYS.SYSTEM_JOB_SUCCESS: {
			return {
				...updateWithBlogPost(state, action.payload),
				blogGenerationJobId: null,
				blogGenerated: true,
			};
		}
		case REDUCER_ACTION_KEYS.BLOG_GENERATION_FAILED: {
			return {
				...state,
				blogGenerated: false,
				blogGenerationJobId: '',
			};
		}
		case REDUCER_ACTION_KEYS.TOGGLE_FILE_INPUT_MODAL: {
			return {
				...state,
				fileInputModalOpen: action.payload.open,
				suggestedImage: false,
			};
		}
		case REDUCER_ACTION_KEYS.SET_CONTENT: {
			return {
				...state,
				content: action.payload.content,
			};
		}
		case REDUCER_ACTION_KEYS.AI_WIZARD_CLOSED: {
			return {
				...state,
				showingAiContentGenWizard: false,
				showScheduleAction: true,
			};
		}
		case REDUCER_ACTION_KEYS.SET_SEND_ON_BEHALF_OF_USER: {
			return {
				...state,
				sendFromUser: action.payload,
			};
		}
		case REDUCER_ACTION_KEYS.SET_STATE: {
			return {
				...state,
				...(action.payload || {}),
				sendFromUser: action.payload.sendFromUser ?? null,
				scheduledSendDate: action.payload.scheduledSendDate || null,
			};
		}
		case REDUCER_ACTION_KEYS.DELETE_BLOG_POST: {
			return {
				...state,
				showConfirmationModal: action.payload.value,
			};
		}
		case REDUCER_ACTION_KEYS.SHOWINITIAL_AI_WIZARD: {
			return {
				...state,
				showingAiContentGenWizard: action.payload.value,
			};
		}
		case REDUCER_ACTION_KEYS.IMAGE_SUCCESSFULLY_UPLOADED: {
			return {
				...state,
				selectedImage: { ...action.payload, source: Api.IAttachmentUploadSource.Pixabay },
				imageSuggestingLoading: false,
				suggestedImage: true,
			};
		}
		case REDUCER_ACTION_KEYS.GENERATING_CONTENT: {
			return {
				...state,
				imageSuggestingLoading: action.payload.imageSuggestingLoading,
				imageState: action.payload.imageState,
			};
		}
		default:
			return state;
	}
}
const DefaultInitialState: IState = {
	title: '',
	blogGenerationJobId: '',
	blogId: '',
	content: createRichContentEditorStateWithText(''),
	selectedImage: null,
	showingAiContentGenWizard: true,
	fileInputModalOpen: false,
	blogGenerated: false,
	showScheduleAction: false,
	sendFromUser: null,
	status: null,
	scheduledSendDate: null,
	showConfirmationModal: false,
	imageState: ImageStage.NotLoaded,
	imageSuggestingLoading: false,
	suggestedImage: false,
};

export const useBlogPostEditorContextController = ({
	intializer,
}: {
	intializer?: (initialState: IState) => Partial<IState>;
} = {}) => {
	const [state, dispatch] = React.useReducer(reducer, { ...DefaultInitialState }, initState => {
		return {
			...initState,
			...(intializer ? intializer(initState) : {}),
		};
	});

	return {
		state,
		dispatch,
	};
};

export type IBlogPostEditorContext = ReturnType<typeof useBlogPostEditorContextController>;
export const BlogPostEditorContext = React.createContext<IBlogPostEditorContext>(null);

export const useBlogPostEditorContext = () => useContextGuard(BlogPostEditorContext, 'BlogPostEditorContext');

export function validateContent(state: IState) {
	const errors: string[] = [];
	if (!state.title) {
		errors.push('Please enter a title');
	}
	if (!state.content.hasContent()) {
		errors.push('Please enter content');
	}
	if (!state.selectedImage) {
		errors.push('Please select an image');
	}
	if (!state.sendFromUser) {
		errors.push('Please select user as the post creator/author');
	}
	return errors;
}

export function useBlogPostEditingRequest({
	impersonationContext,
}: { impersonationContext?: Api.IImpersonationContext } = {}) {
	const location = useLocation<ILocationState<any, BlogPostRequest> | null>();
	const userSession = useUserSession();
	const postContext = React.useContext(BlogPostContext);
	const { blogPost, request, setRequest } = postContext;
	const postEditorContext = useBlogPostEditorContextController({
		intializer: initState => {
			const req = location.state?.model || request;
			if (blogPost?.sendFromUserId) {
				initState.sendFromUser =
					blogPost.sendFromUserId === req?.sendFromUser?.id
						? req.sendFromUser
						: ({ id: blogPost.sendFromUserId } as Api.IUser);
			} else if (req?.sendFromUser) {
				initState.sendFromUser = req.sendFromUser;
			} else if (req?.post?.creator) {
				initState.sendFromUser = {
					...req.post.creator,
				} as Api.IUser;
			} else if (impersonationContext?.account?.id && impersonationContext?.user) {
				initState.sendFromUser = impersonationContext.user;
			} else if (!impersonationContext?.account?.id && userSession.user) {
				initState.sendFromUser = userSession.user;
			}
			return initState;
		},
	});

	React.useEffect(() => {
		if (location.state?.model) {
			setRequest(location.state.model);
			if (!blogPost && location.state.model.post) {
				postContext.setBlogPost(location.state.model.post);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		...postContext,
		request: request ?? location.state?.model,
		blogPost: blogPost ?? location.state?.model?.post,
		postEditorContext,
	} as const;
}
