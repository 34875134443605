import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { animated, useSpring } from 'react-spring-legacy';
import { bs } from '../../../styles/styles';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { styleSheet } from './styles';

interface IProps {
	animateRatingChanges?: boolean;
	className?: string;
	fillColor?: string;
	onValueChanged?(value: number): void;
	readonly?: boolean;
	starStyles?: StyleDeclarationValue[];
	strokeColor?: string;
	styles?: StyleDeclarationValue[];
	total: number;
	value?: number;
	showEmoji?: boolean;
}

const ratings = [
	{
		emoji: '',
		name: '',
		value: null,
	},
	{
		emoji: '😞',
		name: 'Very Poor',
		value: 1,
	},
	{
		emoji: '🙁',
		name: 'Poor',
		value: 2,
	},
	{
		emoji: '🙂',
		name: 'Good',
		value: 3,
	},
	{
		emoji: '🤩',
		name: 'Very Good',
		value: 4,
	},
	{
		emoji: '🥰',
		name: 'Excellent',
		value: 5,
	},
];

export const StarRating: React.FC<IProps> = ({
	animateRatingChanges,
	className,
	fillColor,
	onValueChanged,
	readonly,
	starStyles = [],
	strokeColor,
	styles = [],
	total,
	value,
	showEmoji = false,
}) => {
	const canAnimateRatingChanges = animateRatingChanges && !readonly;
	const [rating, setRating] = React.useState<number>(value || 0);
	const onStarClicked = React.useCallback(
		(index: number) => (e: React.MouseEvent<HTMLElement>) => {
			const nextValue = index + 1;
			if (onValueChanged) {
				onValueChanged(nextValue);
			} else {
				setRating(nextValue);
			}
			e.preventDefault();
			e.stopPropagation();
		},
		[onValueChanged]
	);

	React.useEffect(() => {
		// @ts-ignore
		if (value >= 0) {
			// @ts-ignore
			setRating(value);
		}
	}, [value]);

	const animationStarStyles = canAnimateRatingChanges ? [styleSheet.starAnimatable, styleSheet.starHover] : [];

	const [, setHovering] = React.useState<boolean>(false);
	const [hoverIndex, setHoverIndex] = React.useState<number>(-1);
	const onToggleHover = React.useCallback(
		(on: boolean) => () => {
			setHovering(on);
			if (!on) {
				setHoverIndex(-1);
			}
		},
		[]
	);

	const onToggleStarHover = React.useCallback(
		(on: boolean, index: number) => () => {
			if (on) {
				setHoverIndex(index);
			}
		},
		[]
	);

	const emojiAnimation = useSpring({
		opacity: (rating || hoverIndex) >= 0 ? 1 : 0,
		transform: `translateY(${(rating || hoverIndex) >= 0 ? 0 : 20}px)`,
		config: { tension: 200, friction: 15, clamp: true },
	});

	return (
		<>
			{showEmoji ? (
				<div className={css(bs.fullWidth)}>
					<animated.figure
						style={emojiAnimation}
						className={css(bs.flex, bs.flexCenter, bs.flexCol, bs.gap2, styleSheet.emojiRating)}
					>
						<picture>{ratings[rating || hoverIndex + 1]?.emoji}</picture>
						<figcaption className={css(bs.flex, bs.flexCenter)}>{ratings[rating || hoverIndex + 1]?.name}</figcaption>
					</animated.figure>
				</div>
			) : null}
			<div
				className={`${css(styleSheet.container, ...styles)} star-rating ${className || ''}`}
				onMouseEnter={canAnimateRatingChanges ? onToggleHover(true) : undefined}
				onMouseLeave={canAnimateRatingChanges ? onToggleHover(false) : undefined}
			>
				{Array(total)
					.fill(null)
					.map((__, i) => {
						const filled = hoverIndex >= 0 ? i <= hoverIndex : i < rating;
						const icon = (
							<StarIcon
								fillColor={fillColor}
								filled={filled}
								onMouseEnter={canAnimateRatingChanges ? onToggleStarHover(true, i) : undefined}
								onMouseLeave={canAnimateRatingChanges ? onToggleStarHover(false, i) : undefined}
								strokeColor={strokeColor}
								styles={[styleSheet.star, ...animationStarStyles, ...starStyles]}
							/>
						);
						if (readonly) {
							return <span key={`${i}`}>{icon}</span>;
						}
						return (
							<button key={`${i}`} onClick={onStarClicked(i)}>
								{icon}
							</button>
						);
					})}
			</div>
		</>
	);
};
