import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { v4 as uuid } from 'uuid';
import {
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '../../../models';
import { Topics } from '../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../models/LocalNotifications';
import { useEventLogging } from '../../../models/Logging';
import { copyToClipboard } from '../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { invalidateInfiniteSurveys, useUpdateSatisfactionSurveyCustomFormMutation } from '../../../queries';
import { Button } from '../../components/Button';
import { FabContext } from '../../components/FabContext';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { SquareLinkIcon } from '../../components/svgs/icons/SquareLinkIcon';
import { white } from '../../styles/colors';
import { baseStyleSheet, bs } from '../../styles/styles';
import { EditSurveyContext, IEditSurveyContext } from './context';
import { AdditionalQuestions, BasicSurveyInfo, ReviewRequestOptions } from './presentation';
import { styleSheet } from './styles';

interface IProps
	extends RouteComponentProps<any>,
		IModalContext<Api.SatisfactionSurveyViewModel>,
		IImpersonationContextComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IEditSurveyComponent {
	BasicSurveyInfo: React.FC;
	ReviewRequestOptions: React.FC;
	AdditionalQuestions: React.FC;
}

const getDefaultSatisfactionSurvey = (userSession: Api.UserSessionContext): Api.ISatisfactionSurvey => {
	const fieldId = uuid();
	return {
		_type: 'SatisfactionSurvey',
		companyDisplayName: userSession.account.companyName || '',
		intro: 'How would you rate your overall experience with us?',
		reviewRequestSettings: {
			isEnabled: false,
			ratings: [4, 5],
			reviewRequest:
				'Thank you so much for your kind words and rating. Would you be so generous as to leave us a review? It would really mean the world to us.',
		},
		startDate: moment().toISOString(),
		customFormIsDisabled: true,
		customForm: {
			name: '',
			fields: [
				{
					fieldType: Api.FormFieldType.String,
					id: fieldId,
					label: '',
					isHidden: false,
					isOptional: true,
				},
			],
		},
		customFormMetaData: [
			{
				fieldId,
				isConditional: false,
				ratings: [1, 2, 3],
			},
		],
	};
};

const SatisfactionSurveyBase: React.FC<IProps> & Partial<IEditSurveyComponent> = observer(props => {
	const { className, styles = [], impersonationContext, parentModal } = props;
	const userSession = useUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('EditSurvey');
	const loc = useLocation();
	const { postNotification } = useLocalNotificationService();
	const surveyCustomFormMutation = useUpdateSatisfactionSurveyCustomFormMutation({
		onError: error => {
			errorMessages.pushApiError(error);
		},
		onSuccess: () => {
			invalidateInfiniteSurveys();
		},
	});

	const locationState: ILocationState<Api.SatisfactionSurveyViewModel, any> = loc.state || {};
	// @ts-ignore
	const surveyVmRef = React.useRef<Api.SatisfactionSurveyViewModel>(locationState.viewModel);
	const isArchived = Boolean(surveyVmRef.current?.archiveMoment);

	const editSurveyContextRef =
		React.useRef<
			[IEditSurveyContext<Api.ISatisfactionSurvey>, (ctx: IEditSurveyContext<Api.ISatisfactionSurvey>) => void]
		>(null);
	const updateSurveyContext = React.useRef(
		(contextPropsToUpdate: Partial<IEditSurveyContext<Api.ISatisfactionSurvey>>) => {
			// @ts-ignore
			const [ctx, setCtx] = editSurveyContextRef.current;
			setCtx({
				...ctx,
				...(contextPropsToUpdate || {}),
			});
		}
	).current;
	const [editSurveyContext, setEditSurveyContext] = React.useState<IEditSurveyContext<Api.ISatisfactionSurvey>>({
		askForReview: true,
		setAskForReview: (askForReview: boolean) => updateSurveyContext({ askForReview }),
		setSurvey: (s: Api.ISatisfactionSurvey) => updateSurveyContext({ survey: s }),
		survey: (locationState.viewModel?.toJs() as Api.ISatisfactionSurvey) || getDefaultSatisfactionSurvey(userSession),
	});
	editSurveyContextRef.current = [editSurveyContext, setEditSurveyContext];

	const [saving, setSaving] = React.useState<boolean>(false);
	const save = React.useCallback(async () => {
		// check for missing required info
		const { survey } = editSurveyContext;
		if (!survey.name) {
			errorMessages.push({
				messages: ['Please provide a name for this survey.'],
			});
			return;
		}

		if (!survey.intro) {
			errorMessages.push({
				messages: ['Please provide an intro for this survey.'],
			});
			return;
		}

		if (!survey.startDate) {
			errorMessages.push({
				messages: ['Please select a date range option for this survey.'],
			});
			return;
		}

		const satSurvey: Api.ISatisfactionSurvey = survey;
		if (satSurvey.reviewRequestSettings?.isEnabled) {
			if (satSurvey.reviewRequestSettings.ratings?.length === 0) {
				errorMessages.push({
					messages: ['Please provide at least one review rating.'],
				});
				return;
			}

			if (!satSurvey.reviewRequestSettings.reviewRequest || !satSurvey.reviewRequestSettings.reviewUrl) {
				errorMessages.push({
					messages: ['Please provide a review request message and a link to your review site.'],
				});
				return;
			}
		}

		if (!survey.customFormIsDisabled && survey.customForm && survey.customForm.fields.length) {
			if (survey.customForm.fields.length && !survey.customForm.fields[0].label) {
				errorMessages.push({
					messages: ['Please provide at least one additional question.'],
				});
				return;
			}
		}

		const getSurveyCustomFormPayload = (): Api.SatisfactionSurveyCustomFormRequest => {
			if (!survey.customFormIsDisabled && survey.customForm && survey.customForm.fields) {
				const customFormMetadata: Api.CustomFormMetaData[] = survey.customForm.fields
					.map((field: Api.IFormField<string>) => {
						const fieldMetaData = survey.customFormMetaData.find(f => f.fieldId === field.id);
						if (!fieldMetaData?.isConditional) {
							return null;
						}
						const ratings = Array.from({ length: fieldMetaData.ratings.length }, (_, i) => i + 1);
						return {
							fieldId: field.id,
							isConditional: fieldMetaData?.isConditional,
							ratings,
						};
					})
					.filter(Boolean);
				return {
					customForm: {
						name: survey.name,
						fields: survey.customForm.fields,
					},
					customFormMetadata,
				};
			}
			return null;
		};

		const surveyQuestionsPayload: Api.SatisfactionSurveyCustomFormRequest = getSurveyCustomFormPayload();

		try {
			setSaving(true);
			if (surveyVmRef.current?.id) {
				await surveyVmRef.current.update(survey);
				/**
				 * Updates to the survey custom form
				 */
				if (surveyQuestionsPayload) {
					surveyCustomFormMutation.mutate({ request: surveyQuestionsPayload, id: surveyVmRef.current.id });
				}
				postNotification({
					info: surveyVmRef.current.toJs(),
					topic: Topics.EDIT_SURVEY,
				});
			} else {
				const surveyVm: Api.SatisfactionSurveyViewModel =
					// @ts-ignore
					await Api.SurveyViewModel.create<Api.SatisfactionSurveyViewModel>(
						userSession,
						satSurvey,
						impersonationContext
					);
				/**
				 * If there are questions to save, then save them
				 */
				if (surveyQuestionsPayload) {
					surveyCustomFormMutation.mutate({ request: surveyQuestionsPayload, id: surveyVm.toJs().id });
				}
				surveyVmRef.current = surveyVm;
				postNotification({
					info: surveyVm.toJs(),
					topic: Topics.CREATE_SURVEY,
				});
			}
			invalidateInfiniteSurveys();
			updateSurveyContext({ survey: surveyVmRef.current.toJs() });
			setSaving(false);

			toaster.push({
				message: 'Survey saved.',
				type: 'successMessage',
			});
			parentModal?.onRequestClose(surveyVmRef.current);
		} catch (error) {
			logApiError('SurveySave-Error', error);
			errorMessages.pushApiError(error);
			setSaving(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSurveyContext]);

	const onCopyLinkClicked = () => {
		if (copyToClipboard(surveyVmRef.current.anonymousLink)) {
			toaster.push({
				message: 'Link copied to clipboard',
				type: 'successMessage',
			});
		} else {
			toaster.push({
				message: 'Error copying link to clipboard',
				type: 'errorMessage',
			});
		}
	};

	const onPreviewLinkClicked = () => {
		window.open(surveyVmRef.current.anonymousLink, '_blank');
	};

	return (
		<EditSurveyContext.Provider value={editSurveyContext}>
			<div className={`${css(styleSheet.satisfactionSurveyContainer, ...styles)} edit-survey ${className || ''}`}>
				<MultiContainerHeader
					fullscreenHeader={`${editSurveyContext?.survey?.id ? (isArchived ? 'View' : 'Edit') : 'New'} Survey`}
				/>
				<FabContext appearance={{ hidden: true }} />
				<header className={css(styleSheet.header)}>
					<div className={css(styleSheet.headerActions)}>
						{isArchived && <span className={css(styleSheet.archivedLabel)}>ARCHIVED</span>}
						{editSurveyContext?.survey?.id ? (
							<button
								className={css(bs.textBrandPrimary)}
								disabled={!editSurveyContext?.survey?.id}
								onClick={onPreviewLinkClicked}
								title={!editSurveyContext?.survey?.id ? 'Please save in order to generate this link' : undefined}
							>
								<span>Preview Survey</span>
							</button>
						) : null}
						<button
							className={css(baseStyleSheet.ctaButtonSmall, styleSheet.copyLinkButton)}
							disabled={!editSurveyContext?.survey?.id}
							onClick={onCopyLinkClicked}
							title={!editSurveyContext?.survey?.id ? 'Please save in order to generate this link' : undefined}
						>
							<SquareLinkIcon fillColor={white} />
							<span>Copy Survey Link</span>
						</button>
					</div>
				</header>
				<div className={css(styleSheet.body)}>
					<SatisfactionSurvey.BasicSurveyInfo />
					<SatisfactionSurvey.ReviewRequestOptions />
				</div>
				<SatisfactionSurvey.AdditionalQuestions />
				<footer className={css(bs.flex, bs.flexRow, bs.pb8)}>
					<Button
						kind='primary'
						className={css(bs.mr2)}
						disabled={surveyVmRef.current?.isBusy || isArchived}
						onClick={save}
						isLoading={saving || surveyVmRef.current?.isBusy}
						label='Save'
					/>

					<Button
						kind='reverse'
						label='Cancel'
						className={css(bs.ctaButtonReverse)}
						onClick={() => parentModal.onRequestClose()}
					/>
				</footer>
			</div>
		</EditSurveyContext.Provider>
	);
});

export const SatisfactionSurvey = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(SatisfactionSurveyBase);

SatisfactionSurvey.BasicSurveyInfo = BasicSurveyInfo;
SatisfactionSurvey.ReviewRequestOptions = ReviewRequestOptions;
SatisfactionSurvey.AdditionalQuestions = AdditionalQuestions;
