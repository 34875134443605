import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import {
	useAttachmentQuery,
	useConvertSocialVideoMutation,
	useGetSystemJob,
	useUploadVideoAttachmentMutation,
} from '../../../../queries';

export const useVideoUploadMutation = ({
	onError,
	onSuccess,
	impersonationContext,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (jobId: string) => void;
	impersonationContext?: Api.IImpersonationContext;
}) => {
	const { mutateAsync: getPresignedUrlAsync } = useUploadVideoAttachmentMutation();
	const { mutateAsync: convertVideo } = useConvertSocialVideoMutation();

	return useMutation({
		mutationFn: async ({
			file,
			duration,
			height,
			width,
			postId,
			forUserId,
		}: {
			file: File;
			duration: number;
			height: number;
			width: number;
			postId: string;
			forUserId: string;
		}) => {
			const request: Api.IPresignedUrlRequest = {
				contentLength: file.size,
				contentType: file.type,
				duration,
				fileName: file.name,
				height,
				width,
			};

			const presignedResult = await getPresignedUrlAsync({ forUserId, request, impersonationContext });

			await fetch(presignedResult.presignedUrl, {
				body: file,
				headers: { 'Content-Type': file.type },
				method: 'PUT',
			});

			await convertVideo({ forUserId, jobId: presignedResult.jobId, postId, impersonationContext });

			return presignedResult.jobId;
		},
		onError,
		onSuccess,
	});
};

export function useVideoAttachmentFromSystemJob({
	jobId,
	impersonationContext,
}: { jobId: string; impersonationContext?: Api.IImpersonationContext }) {
	const [attachmentId, setAttachmentId] = useState<string>();
	const [errorMessage, setErrorMessage] = useState<string>();

	const isAttachmentQueryEnabled = Boolean(attachmentId);
	const { data: videoAttachment } = useAttachmentQuery({
		enabled: isAttachmentQueryEnabled,
		id: attachmentId,
		impersonationContext,
	});

	const isSystemJobQueryEnabled = Boolean(jobId);
	useGetSystemJob<Api.IMediaConvertSystemJob>({
		enabled: isSystemJobQueryEnabled,
		jobId,
		onSuccess: data => {
			if (data?.attachmentId) {
				setAttachmentId(data.attachmentId);
			}

			if (data?.status?.length > 0) {
				setErrorMessage(data.status);
				return;
			}

			if (data?.recordsFailed > 0) {
				setErrorMessage('Video conversion failed.');
			}
		},
		refetchInterval: data => {
			if (!jobId) {
				return false;
			}
			return !data?.attachmentId ? 3000 : false;
		},
		refetchOnWindowFocus: false,
	});

	const resetErrorMessage = useCallback(() => {
		setErrorMessage(null);
	}, []);

	return { errorMessage, resetErrorMessage, videoAttachment };
}
