import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Noop, getDisplayName } from '../../../../extViewmodels/Utils';
import {
	IImpersonationContextComponentProps,
	IPeopleSearchAddedTagsState,
	ImpersonationContextKey,
} from '../../../../models';
import { EmailComposerContext } from '../../../../models/Email';
import { useEventLogging } from '../../../../models/Logging';
import { PersistentStorageManager } from '../../../../models/Storage';
import { getDefaultOwnedByContactFilterFilterCriteria } from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	ComposeResourceEmailViewModel,
	ContactFilterCriteriaProperty,
	ContactViewModel,
	DefaultBulkSendExcludedFilterCriteria,
	DefaultBulkSendExcludedTags,
	FilterOperator,
	IAccountTag,
	IContact,
	IContactFilterCriteria,
	IContactsFilterRequest,
	IOperationResultNoValue,
	IOwnershipFilter,
	SavedSearchViewModel,
	SavedSearchesViewModel,
	SendEmailFrom,
	TagsViewModel,
	VmUtils,
} from '../../../../viewmodels/AppViewModels';
import { Checkbox } from '../../../components/Checkbox';
import { GlobalSearch } from '../../../components/GlobalSearch';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { RadioButton } from '../../../components/RadioButton';
import { PaperPlaneIcon } from '../../../components/svgs/icons/PaperPlaneIcon';
import { SearchIcon } from '../../../components/svgs/icons/SearchIcon';
import { XIcon } from '../../../components/svgs/icons/XIcon';
import { brandPrimaryHover, navigation } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { PeopleCollapsibleSection } from '../../PeopleV2/PeopleContainer/PeopleCollapsibleSection';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps {
	/** Default === true */
	applyOwnedByCriteria?: boolean;
	className?: string;
	hideFooter?: boolean;
	nextCtaText?: React.ReactNode;
	onNextClick?(explicitelyPreventContactFilterRequest?: boolean): void;
	canClearFilters?: boolean;
}

interface ITag {
	selected: boolean;
	tag: string;
}

const MaxItemsToShowByDefault = 5;

const SearchBase: React.FC<IProps> = ({
	applyOwnedByCriteria = true,
	className = '',
	impersonationContext,
	nextCtaText,
	onNextClick,
	canClearFilters = true,
}) => {
	const userSession = useUserSession();
	const logger = useEventLogging();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const [tags, setTags] = useState<ITag[]>([]);
	const [selectedContacts, setSelectedContacts] = useState<ContactViewModel[]>([]);

	const [selectedSavedSearch, setSelectedSavedSearch] = useState<SavedSearchViewModel>(null);
	const [sendingTest, setSendingTest] = useState(false);
	const searches = useRef(new SavedSearchesViewModel(userSession)).current;
	const popularTags = useRef(new TagsViewModel(userSession).impersonate(impersonationContext)).current;
	const { emailComposer, sendTestEmail } = React.useContext(EmailComposerContext) || {};
	const sendFlowGlobalSearchAddedTags = useRef(
		`sendFlowGlobalSearchAddedTags-${impersonationContext?.account?.id ?? userSession?.account?.id}`
	).current;

	useEffect(() => {
		let startingTags: ITag[] = [];
		getStoredTags()
			.then(storedTags => {
				startingTags = storedTags;
				return popularTags.getSuggestedTagsForContactsV2(false, 6);
			})
			.then((result = []) => {
				startingTags = [...result.map(x => ({ selected: false, tag: x.tag })), ...startingTags].filter(
					x => !startingTags.includes(x)
				);
				setTags(startingTags);
			})
			.catch((err: IOperationResultNoValue) => {
				logger.logApiError('LoadSuggestedTags-Error', err);
			});

		// clear any previously selected tags from criteria
		// this is to handle case when user selects tags, then
		// moves to the next screen, uses the advanced tag filter,
		// and then comes back to the first screen.
		const contactFilterRequest: IContactsFilterRequest = { criteria: [] };

		if (emailComposer?.emailMessage && !emailComposer?.isFollowUp && canClearFilters) {
			setSelectedContacts(
				emailComposer.emailMessage.contactsToAdd.filter(c => {
					return !emailComposer.emailMessage.contactsToOmit.getById(c.id);
				})
			);

			emailComposer.emailMessage.contactsFilterRequest = {
				contactFilterRequest,
				ownershipFilter: emailComposer.emailMessage.contactsFilterRequest?.ownershipFilter,
			};
			emailComposer.setAllowContactFilterTagSearchChangesOverride(true);
		}

		emailComposer?.resetRecipientsResultsList();

		searches.loadAll()?.catch((err: IOperationResultNoValue) => {
			errorMessages.pushApiError(err);
			logger.logApiError('LoadSavedSearches-Error', err);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const sorted = VmUtils.sortContactFilterCriteria(
			emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest
		);
		const selectedTags = tags.filter(t => t.selected);

		let contactFilterRequest: IContactsFilterRequest = null;
		let ownershipFilter: IOwnershipFilter = null;

		if (selectedSavedSearch) {
			const notEmptyCriteria = selectedSavedSearch.filter?.criteria?.filter(x => x.criteria?.length || x.property >= 0);

			contactFilterRequest = {
				criteria: [...notEmptyCriteria, ...DefaultBulkSendExcludedFilterCriteria],
			};
		} else {
			if (!emailComposer?.isFollowUp && tags.filter(t => t.selected).length > 0) {
				ownershipFilter =
					!impersonationContext &&
					applyOwnedByCriteria &&
					!emailComposer?.emailMessage?.isSendFromOption(SendEmailFrom.ContactOwner)
						? emailComposer?.emailMessage?.contactsFilterRequest.ownershipFilter ??
							getDefaultOwnedByContactFilterFilterCriteria(
								userSession,
								emailComposer?.emailMessage?.options?.sendEmailFromUserId ||
									emailComposer?.emailMessage?.options?.sendEmailFromUser?.id
							)
						: emailComposer?.emailMessage?.contactsFilterRequest.ownershipFilter;
				contactFilterRequest = {
					criteria: VmUtils.concatContactFilterCriteria(
						[
							{
								criteria: [
									...selectedTags.map(tag => {
										return {
											property: ContactFilterCriteriaProperty.Tag,
											value: tag.tag,
										};
									}),
								],
								op: FilterOperator.Or,
							} as IContactFilterCriteria,
							...sorted.filters.filter(x => x.property !== ContactFilterCriteriaProperty.OwnedBy),
						].filter(x => !!x),
						Array.from(DefaultBulkSendExcludedTags).map(x => ({
							op: FilterOperator.Not,
							property: ContactFilterCriteriaProperty.Tag,
							value: x,
						}))
					),
				};
			}
		}

		if (emailComposer?.emailMessage && !!contactFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest = {
				...(emailComposer.emailMessage.contactsFilterRequest ?? {}),
				contactFilterRequest,
				ownershipFilter,
			};
		}
		emailComposer?.resetRecipientsResultsList();
	}, [applyOwnedByCriteria, emailComposer, impersonationContext, selectedSavedSearch, tags, userSession]);

	const addToPersistentUserSelectedTags = React.useCallback((tag: string) => {
		PersistentStorageManager.local
			.getObject<IPeopleSearchAddedTagsState>(sendFlowGlobalSearchAddedTags)
			.then(storedPeopleTagsState => {
				const userStoredTags = storedPeopleTagsState?.tags ?? [];
				const persistentTags = Array.from(new Set([...userStoredTags, tag]));
				PersistentStorageManager.local.setObject<IPeopleSearchAddedTagsState>(sendFlowGlobalSearchAddedTags, {
					tags: persistentTags,
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isForResource = React.useCallback(() => {
		return (emailComposer as ComposeResourceEmailViewModel)?.resourceSelector;
	}, [emailComposer]);

	const isSelectedTag = (t: string) => {
		return !!emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria.find(c => {
			return !!c?.criteria?.find(cc => {
				return cc.property === ContactFilterCriteriaProperty.Tag && cc.value === t;
			});
		});
	};

	const getStoredTags = React.useCallback(() => {
		return PersistentStorageManager.local
			.getObject<IPeopleSearchAddedTagsState>(sendFlowGlobalSearchAddedTags)
			.then(storedTags => {
				const userStoredTags = storedTags?.tags ?? [];
				if (userStoredTags.length === 0) {
					PersistentStorageManager.local.remove(sendFlowGlobalSearchAddedTags);
				}

				return userStoredTags.map(ust => ({ selected: false, tag: ust }));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tags]);

	const onContactSelected = React.useCallback(
		async (contactModel: IContact) => {
			const contact = new ContactViewModel(userSession, contactModel);

			if (emailComposer.emailMessage.contactsToOmit.has(contact)) {
				emailComposer.emailMessage.contactsToOmit.removeItems([contact]);
			}

			emailComposer.emailMessage.contactsToAdd.splice(0, 0, contact);

			if (!emailComposer?.isFollowUp) {
				setSelectedContacts(
					emailComposer.emailMessage.contactsToAdd.filter(c => !emailComposer.emailMessage.contactsToOmit.getById(c.id))
				);
			}
			try {
				const getEmailPromise = isForResource()
					? (emailComposer as ComposeResourceEmailViewModel).getEmailApproximationWithResource()
					: emailComposer.emailMessage.getEmailApproximation();
				await getEmailPromise;
			} catch (err) {
				logger.logApiError('HasEmailApproximationLoad-Error', err);
			}

			if (emailComposer.emailMessage.options?.sendEmailFrom === SendEmailFrom.ContactOwner) {
				emailComposer.emailMessage.options = {
					...emailComposer.emailMessage.options,
					sendEmailFrom: SendEmailFrom.CurrentUser,
				};
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[emailComposer, isForResource]
	);

	const onPreviewRecipientsClick = () => {
		onNextClick(tags.filter(t => t.selected).length === 0 && selectedContacts.length > 0);
	};

	const onRemoveContactClick = (contact: ContactViewModel) => () => {
		emailComposer.emailMessage.contactsToAdd.removeItems([contact]);
		setSelectedContacts(selectedContacts.filter(c => c.id !== contact.id));
	};

	const onSavedSearchClick = (search: SavedSearchViewModel) => () => {
		setSelectedSavedSearch(search);
		setTags(tags.map(t => ({ selected: false, tag: t.tag })));
	};

	const onSelectedTagsChanged = React.useCallback(
		(newTag: string) => {
			const updatedTags = tags.filter(t => t.tag !== newTag);
			updatedTags.unshift({ selected: true, tag: newTag });
			setTags(updatedTags);
		},
		[tags]
	);

	const onSendTestClick = async () => {
		if (sendTestEmail) {
			try {
				setSendingTest(true);
				await sendTestEmail();

				toaster.push({
					message: 'Test email sent successfully.',
					type: 'successMessage',
				});
				setSendingTest(false);
			} catch (err) {
				setSendingTest(false);

				logger.logApiError('GlobalSearchSentTestEmail-Error', err);

				errorMessages.pushApiError(err);
			}
		}
	};

	const onTagClick = (t: string) => () => {
		let updatedTags = [...tags];
		const wasSelected = !isSelectedTag(t);
		if (wasSelected) {
			// clear selected saved searches from the existing criteria because cannot include saved search
			// when also using individual tags.

			setSelectedSavedSearch(null);
			const existingTag = updatedTags.find(ut => ut.tag === t);
			if (existingTag) {
				updatedTags = updatedTags.map(ut => {
					if (ut.tag === t) {
						ut.selected = true;
					}

					return ut;
				});
			} else {
				updatedTags.push({ selected: true, tag: t });
			}
		} else {
			updatedTags = updatedTags.map(ut => {
				if (ut.tag === t) {
					ut.selected = false;
				}

				return ut;
			});
		}

		setTags(updatedTags);
	};

	const onTagSelected = React.useCallback(
		(tag: IAccountTag) => {
			// clear selected saved searches from the existing criteria because cannot include saved search
			// when also using individual tags.

			setSelectedSavedSearch(null);

			onSelectedTagsChanged(tag.tag);

			addToPersistentUserSelectedTags(tag.tag);
		},
		[onSelectedTagsChanged, addToPersistentUserSelectedTags]
	);

	const renderContacts = () => {
		return selectedContacts.map((c, i) => (
			<div className={css(styleSheet.flex, styleSheet.itemContainer, styleSheet.contactContainer)} key={i}>
				<button className={css(styleSheet.removeContactButton)} onClick={onRemoveContactClick(c)}>
					<XIcon height={12} width={12} fillColor={navigation} />
				</button>
				<div className={`contact-content ${css(styleSheet.contentContainer)}`}>
					<span>{getDisplayName(c)}</span>
					<span className={css(styleSheet.smallGray)}>{c.jobTitle ?? ''}</span>
				</div>
			</div>
		));
	};

	const renderSavedSearches = () => {
		// Do not allow Levitate admin users to view saved searches of users
		// There is no api allowed for fetching them with impersonation
		if (searches.isBusy || impersonationContext?.isValid) {
			return [];
		}

		return searches.searches.map((s, i) => {
			const isSelected = s.id === selectedSavedSearch?.id;
			return (
				<div className={css(styleSheet.itemContainer)} key={s.id}>
					<RadioButton
						checked={isSelected}
						key={s.id}
						id={`saved-searches-${i}`}
						name='saved-searches'
						onChange={Noop}
						onClick={onSavedSearchClick(s)}
						readOnly={true}
					>
						<div className={css(styleSheet.contentContainer)}>
							<span
								className={`saved-search ${css(
									styleSheet.pill,
									isSelected ? styleSheet.pillSelected : styleSheet.pillUnselected
								)}`}
							>
								<SearchIcon fillColor={isSelected ? '#fff' : brandPrimaryHover} />
								{s.name}
							</span>
						</div>
					</RadioButton>
				</div>
			);
		});
	};

	const renderTags = () => {
		return tags.map(({ tag, selected }, i) => {
			const isSelected = selected;
			return (
				<div className={css(styleSheet.flex, styleSheet.itemContainer)} key={i}>
					<Checkbox checked={isSelected} id={`${i}`} onChange={onTagClick(tag)} />
					<div className={css(styleSheet.contentContainer)} onClick={onTagClick(tag)}>
						<span
							className={`tag ${css(
								styleSheet.pill,
								isSelected ? styleSheet.pillSelected : styleSheet.pillUnselected
							)}`}
						>
							{tag}
						</span>
					</div>
				</div>
			);
		});
	};

	return (
		<div
			key={impersonationContext?.isValid ? impersonationContext.user?.id : 'tags'}
			className={`${css(styleSheet.tags)} ${className}`}
		>
			<div className={css(styleSheet.tagsInner)}>
				{!!sendTestEmail && (
					<div className={css(styleSheet.sendATestWrapper)}>
						<button className={css(styleSheet.sendATestButton)} disabled={sendingTest} onClick={onSendTestClick}>
							{sendingTest ? (
								<LoadingSpinner type='tiny' className={css(baseStyleSheet.absoluteCenter)} />
							) : (
								<>
									<PaperPlaneIcon />
									<span>Send a Test</span>
								</>
							)}
						</button>
					</div>
				)}
				<div className={css(styleSheet.tagsHeader)}>Select Recipients to Send:</div>
				<div className={css(styleSheet.globalSearch)}>
					<GlobalSearch
						onContactSelected={
							emailComposer?.emailMessage?.options.sendEmailFrom !== SendEmailFrom.ContactOwner
								? onContactSelected
								: null
						}
						onTagSelected={onTagSelected}
					/>
				</div>
				{tags.length > 0 && (
					<div className={css(styleSheet.sectionContainer)}>
						<div className={css(styleSheet.header)}>Popular Tags</div>
						<div className={css(styleSheet.tagsContainer)}>
							<PeopleCollapsibleSection
								defaultCountToShow={MaxItemsToShowByDefault}
								items={renderTags()}
								itemHeightPx={31}
								showLoader={false}
							/>
						</div>
					</div>
				)}
				{searches.searches.length > 0 && (
					<div className={css(styleSheet.sectionContainer)}>
						<div className={css(styleSheet.header)}>Saved Searches</div>
						<div>
							<PeopleCollapsibleSection
								defaultCountToShow={MaxItemsToShowByDefault}
								items={renderSavedSearches()}
								itemHeightPx={31}
								showLoader={searches.isBusy}
							/>
						</div>
					</div>
				)}
				<div className={css(styleSheet.sectionContainer)}>
					<div className={css(styleSheet.header)}>Contacts</div>
					<div>
						{selectedContacts.length === 0 ? (
							<div className={css(styleSheet.smallGray)} style={{ maxWidth: 220 }}>
								{emailComposer?.emailMessage?.options.sendEmailFrom === SendEmailFrom.ContactOwner
									? 'Selecting individual contacts while sending on behalf of contact owners is not supported.'
									: 'You can use the search to add individual contacts to your campaign.'}
							</div>
						) : (
							<PeopleCollapsibleSection
								defaultCountToShow={MaxItemsToShowByDefault}
								items={renderContacts()}
								itemHeightPx={28}
							/>
						)}
					</div>
				</div>
				<div className={css(styleSheet.tagsFooter)}>
					<button
						className={css(baseStyleSheet.ctaButton)}
						disabled={!selectedContacts.length && !tags.filter(t => t.selected).length && !selectedSavedSearch}
						onClick={onPreviewRecipientsClick}
					>
						<span>{nextCtaText || 'Next: Preview Recipients'}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

const SearchAsObserver = observer(SearchBase);
export const Search = inject(ImpersonationContextKey)(SearchAsObserver);
