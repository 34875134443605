import {
	AutomationsOnHoldViewModel,
	CampaignViewModel,
	CompanyViewModel,
	ContactViewModel,
	IContactFilterCriteria,
	IEntity,
} from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { ILocationState } from '../../../../models';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { CampaignsApprovalViewModel } from '../../../../viewmodels/AppViewModels';
import { BulkHwcBirthdayComposerPage } from '../../../containers/BulkHwcPages/BulkHwcBirthdayComposerPage';
import { BulkHwcFromContactFilterPage } from '../../../containers/BulkHwcPages/BulkHwcFromContactFilterPage';
import { BulkHwcFromContactSelectionPage } from '../../../containers/BulkHwcPages/BulkHwcFromContactSelectionPage';
import { Company } from '../../../containers/Company';
import { Contact } from '../../../containers/Contact';
import { FocusedContact } from '../../../containers/Contact/Focused';
import { EditQueuedCampaign } from '../../../containers/EditQueuedCampaign';
import { EventRegistrationSurvey } from '../../../containers/EventRegistrationSurvey';
import { MultiCampaignSchedulerRoutes } from '../../../containers/MultiCampaignScheduler';
import { PostcardComposer } from '../../../containers/PostcardComposer';
import { SatisfactionSurvey } from '../../../containers/SatisfactionSurvey';
import { TextingCampaignRoutes } from '../../../containers/TextingCampaign';
import { BlogCampaignRoutes } from '../../../containers/blogs';
import { BulkEmailCancelled } from '../../../containers/bulkEmailApproval/BulkEmailCancelled';
import { Opportunity } from '../../../containers/dataBoards/Opportunity';
import { EditSocialMediaPost } from '../../../containers/socialMedia/EditSocialMediaPost';
import { ApprovalPreviewSocialMediaPost } from '../../../containers/socialMediaPostApproval/ApprovalPreviewSocialMediaPost';
import { BrowseAndSendCampaigns } from '../../../containers/templates/BrowseAndSendCampaigns';
import { SelectEmailCampaign } from '../../../containers/templates/SelectEmailCampaign';
import { AdvancedDonationReporting } from '../../AdvancedDonationReporting';
import { AdvancedOpportunityReporting } from '../../AdvancedOpportunityReporting';
import { AdvancedReporting } from '../../AdvancedReporting';
import { PrivateRoute } from '../../PrivateRoute';
import { AutomationsOnHold } from '../../automation/AutomationsOnHold';
import { ContactsSelfImport } from '../../contacts/ContactsSelfImport';
import { DonationsImport } from '../../dataBoards/donations/DonationsImport';
import { IntegrationOwnerships } from '../../integrations/IntegrationOwnerships';
import {
	IMeetingConfigModalLocationState,
	MeetingConfigModal,
} from '../../settings/PersonalSettings/MeetingConfigModal';
import { FullscreenModal } from '../FullscreenModal';
import { styleSheet } from './styles';

interface IFocusedContactPageState {
	initialContactIdsToOmit?: string[];
	initialFilterCriteriaList?: IContactFilterCriteria[];
	initialContactIds?: string[];
	mode: 'contact' | 'kit' | 'suggestions';
}

const _WebFullscreenModal = () => {
	const fullscreenModal = useFullscreenModal();
	const isFocused = fullscreenModal?.history?.location?.pathname?.includes('focused');
	const customHeaderStyles = isFocused ? [styleSheet.focusedHeader] : [];
	const userSession = useUserSession();

	const onRenderEditQueuedCampaign = (props: RouteComponentProps<any>) => {
		const locationState: ILocationState<CampaignViewModel | CampaignsApprovalViewModel, any> =
			fullscreenModal?.history?.location?.state ?? {};
		return (
			<EditQueuedCampaign
				{...props}
				approval={locationState.viewModel instanceof CampaignsApprovalViewModel ? locationState.viewModel : undefined}
				campaign={locationState.viewModel instanceof CampaignViewModel ? locationState.viewModel : undefined}
			/>
		);
	};

	const onRenderAdvancedReportingDashboard = (props: RouteComponentProps<any>) => {
		return <AdvancedReporting {...props} />;
	};

	const onRenderEditSatisfactionSurvey = (props: RouteComponentProps<any>) => {
		return <SatisfactionSurvey {...props} />;
	};

	const onRenderAutomationsOnHold = (props: RouteComponentProps<any>) => {
		const locationState: ILocationState<AutomationsOnHoldViewModel, any> =
			fullscreenModal?.history?.location?.state ?? {};
		return <AutomationsOnHold {...props} automationsOnHold={locationState?.viewModel} />;
	};

	const onRenderBrowseAndSend = (props: RouteComponentProps<any>) => {
		return <BrowseAndSendCampaigns {...props} />;
	};

	const onRenderIntegrationOwnership = (props: RouteComponentProps<any>) => {
		return <IntegrationOwnerships {...props} />;
	};

	const onRenderBulkEmailCancelled = (props: RouteComponentProps<any>) => {
		const locationState: ILocationState<CampaignsApprovalViewModel, any> = fullscreenModal.history.location
			? fullscreenModal.history.location.state || {}
			: {};
		return <BulkEmailCancelled {...props} campaign={locationState?.viewModel} />;
	};

	const onRenderDonationsImport = (props: RouteComponentProps<any>) => {
		return <DonationsImport {...props} onCancel={close} />;
	};

	const onRenderContact = (props: RouteComponentProps<any>) => {
		const locationState: ILocationState<ContactViewModel, boolean> = fullscreenModal.history.location
			? fullscreenModal.history.location.state || {}
			: {};
		return (
			<div className={css(styleSheet.defaultChildStyle)}>
				<Contact
					{...props}
					contact={locationState.viewModel}
					contactId={props.match.params.id}
					hideDeleteButton={locationState.model}
				/>
			</div>
		);
	};

	const onRenderFocusedContact = () => {
		const locationState: IFocusedContactPageState = fullscreenModal.history.location?.state || {};

		const { initialContactIdsToOmit, initialFilterCriteriaList, initialContactIds, mode } = locationState;
		return (
			<div className={css(styleSheet.defaultChildStyle)}>
				<FocusedContact
					initialContactIdsToOmit={initialContactIdsToOmit}
					initialFilterCriteriaList={initialFilterCriteriaList}
					initialContactIds={initialContactIds}
					mode={mode}
				/>
			</div>
		);
	};

	const onRenderCompany = (props: RouteComponentProps<any>) => {
		const locationState: ILocationState<CompanyViewModel, IEntity> = fullscreenModal.history.location
			? fullscreenModal.history.location.state || {}
			: {};
		return (
			<Company
				{...props}
				company={locationState.viewModel}
				companyId={props.match.params.id}
				styles={[styleSheet.defaultChildStyle]}
			/>
		);
	};

	const onRenderMeetingConfig = (props: RouteComponentProps<any>) => {
		const locationState: IMeetingConfigModalLocationState = fullscreenModal.history.location
			? fullscreenModal.history.location.state || {}
			: {};
		return (
			<MeetingConfigModal
				{...props}
				meeting={locationState.viewModel}
				onClose={close}
				styles={[styleSheet.defaultChildStyle]}
			/>
		);
	};

	const close = () => {
		fullscreenModal.history.goBackToLocationBeforeTrackedHistory(true);
	};

	return (
		<FullscreenModal loggingCategory='WebFullscreenModal' customHeaderStyles={customHeaderStyles}>
			<PrivateRoute path='/people/contacts-self-import' userSession={userSession}>
				<ContactsSelfImport onCancel={close} styles={[styleSheet.defaultChildStyle]} />
			</PrivateRoute>
			<PrivateRoute path='/people/focused' render={onRenderFocusedContact} userSession={userSession} />

			<PrivateRoute path='/people/:id' render={onRenderContact} userSession={userSession} />

			<PrivateRoute path='/companies/:id' render={onRenderCompany} userSession={userSession} />
			<PrivateRoute path='/dataBoards/opportunities/:boardId/:id' userSession={userSession}>
				<Opportunity styles={[styleSheet.defaultChildStyle]} />
			</PrivateRoute>
			<PrivateRoute path='/dataBoards/donations/import' render={onRenderDonationsImport} userSession={userSession} />
			<PrivateRoute path='/email/bulk-send-cancelled' render={onRenderBulkEmailCancelled} userSession={userSession} />
			<PrivateRoute path='/email/bulk-send/pending' userSession={userSession}>
				<SelectEmailCampaign />
			</PrivateRoute>
			<PrivateRoute
				path='/automationsonhold/:resourceSelectorId'
				render={onRenderAutomationsOnHold}
				userSession={userSession}
			/>
			<PrivateRoute
				path='/email/campaigns/edit'
				exact={false}
				render={onRenderEditQueuedCampaign}
				userSession={userSession}
			/>
			<PrivateRoute path='/email/campaigns/create' render={onRenderBrowseAndSend} userSession={userSession} />
			<PrivateRoute path='/integrations/ownership' render={onRenderIntegrationOwnership} userSession={userSession} />
			<PrivateRoute path='/meeting/config' render={onRenderMeetingConfig} userSession={userSession} />
			<PrivateRoute
				path='/survey/satisfaction/edit/:id?'
				render={onRenderEditSatisfactionSurvey}
				userSession={userSession}
			/>
			<PrivateRoute path='/survey/event/edit/:id?' userSession={userSession}>
				<EventRegistrationSurvey />
			</PrivateRoute>
			<PrivateRoute
				path={[
					'/social-media/post/create/from-template',
					'/social-media/post/create',
					'/social-media/post/edit-post-draft/:id',
					'/social-media/post/edit-template/:templateId/:suggestionId',
				]}
				userSession={userSession}
			>
				<EditSocialMediaPost />
			</PrivateRoute>
			<PrivateRoute path='/social-media/post/edit-from-post-report' userSession={userSession}>
				<EditSocialMediaPost
					onPostUpdated={() => {
						close();
						return true;
					}}
				/>
			</PrivateRoute>
			<PrivateRoute path='/social-media/post/approval-preview' userSession={userSession}>
				<ApprovalPreviewSocialMediaPost routeContainerClassName={css(styleSheet.defaultChildStyle)} />
			</PrivateRoute>
			<PrivateRoute path='/postcard/:templateId/:step' userSession={userSession}>
				<PostcardComposer />
			</PrivateRoute>
			<PrivateRoute path='/bulk-birthday-postcard/:templateId' userSession={userSession}>
				<BulkHwcBirthdayComposerPage />
			</PrivateRoute>
			<PrivateRoute path='/bulk-contact-selection-postcard/:templateId' userSession={userSession}>
				<BulkHwcFromContactSelectionPage />
			</PrivateRoute>
			<PrivateRoute path='/bulk-contact-filter-postcard/:templateId' userSession={userSession}>
				<BulkHwcFromContactFilterPage />
			</PrivateRoute>
			<PrivateRoute path='/texting-campaign' userSession={userSession}>
				<TextingCampaignRoutes />
			</PrivateRoute>
			<PrivateRoute
				path='/reporting/advanced-reporting/:automationId'
				render={onRenderAdvancedReportingDashboard}
				userSession={userSession}
			/>
			<PrivateRoute
				path='/reporting/advanced-opportunity-reporting'
				render={onRenderAdvancedReportingDashboard}
				userSession={userSession}
			>
				<AdvancedOpportunityReporting />
			</PrivateRoute>
			<PrivateRoute
				path='/reporting/advanced-donations-reporting'
				render={onRenderAdvancedReportingDashboard}
				userSession={userSession}
			>
				<AdvancedDonationReporting />
			</PrivateRoute>
			<PrivateRoute path='/blog' userSession={userSession}>
				<BlogCampaignRoutes />
			</PrivateRoute>
			<PrivateRoute path='/multi-campaign-scheduler' userSession={userSession}>
				<MultiCampaignSchedulerRoutes />
			</PrivateRoute>
		</FullscreenModal>
	);
};

export const WebFullscreenModal = observer(_WebFullscreenModal);
