import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useGetGoogleBusinessMetadata } from '../../../../../queries';
import googleBusinessProfileIntegrationGraphic from '../../../../assets/google-business-profile-integration-graphic.svg';
import googleBusinessProfileLogo from '../../../../assets/google_business_profile_logo.png';
import googleLogo from '../../../../assets/google_logo.png';
import { baseStyleSheet } from '../../../../styles/styles';
import { GoogleBusinessProfileDisconnectedIntegrationGraphic } from '../../../svgs/graphics/GoogleBusinessProfileDisconnectedIntegrationGraphic';
import { IntegrationCard, useIntegrationCard } from '../IntegrationCard';
import { styleSheet } from './styles';

export const DashboardGoogleBusinessProfileIntegrationCard = () => {
	const { data: metadata, status } = useGetGoogleBusinessMetadata({});
	const userSession = useUserSession();

	const isLoaded = status === 'success';

	const { getLogoImgProps, getCloseButtonProps, getCtaButtonProps, getIntegrationTitleProps, showCard } =
		useIntegrationCard({
			pendingAction: Api.PendingActions.ConnectGoogleBusiness,
			closeIntegrationEventName: 'GoogleBusinessProfileIntegrationClosed',
			enableUrlPath: '/integrations/googleBusiness',
			integrationEnableEventName: 'GoogleBusinessProfileIntegrationEnableClick',
			checkApiPendingAction: false,
		});

	if (!showCard || !isLoaded || !userSession.isAdmin) {
		return null;
	}

	return (
		<>
			{metadata.connectionState === Api.GoogleBusinessConnectionState.Unknown && (
				<IntegrationCard
					closeButtonProps={getCloseButtonProps()}
					IntegrationGraphic={<img src={googleBusinessProfileIntegrationGraphic} />}
					logoBgColor='transparent'
					logoImgProps={getLogoImgProps({
						alt: 'Google Business Profile Logo',
						height: 56,
						src: googleBusinessProfileLogo,
						width: 156,
					})}
					ctaButtonProps={getCtaButtonProps({
						children: 'Connect with Google Business Profile',
						styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
					})}
					integrationTitleProps={getIntegrationTitleProps({
						styleDeclaration: styleSheet.integrationTitle,
					})}
				>
					<p>
						We are excited to announce that you can integrate your Google Business Profile with Levitate. With this
						integration, you will have the ability to see reviews, reply with our AI assistant and track your customer
						feedback.
					</p>
				</IntegrationCard>
			)}

			{metadata.connectionState === Api.GoogleBusinessConnectionState.Disconnected && (
				<IntegrationCard
					closeButtonProps={getCloseButtonProps()}
					IntegrationGraphic={GoogleBusinessProfileDisconnectedIntegrationGraphic}
					logoBgColor='transparent'
					logoImgProps={getLogoImgProps({
						alt: 'Google Business Profile Logo',
						height: 23.5,
						src: googleLogo,
						width: 70,
					})}
					ctaButtonProps={getCtaButtonProps({
						children: 'Reconnect with Google Business Profile',
						styleDeclaration: [baseStyleSheet.ctaButtonReverse, styleSheet.ctaButton],
					})}
					headerLabel='Business Profile'
					integrationTitleProps={getIntegrationTitleProps({
						children: <span className={css(styleSheet.disconnectedTitle)}>Integration Disconnected</span>,
						styleDeclaration: styleSheet.integrationTitle,
					})}
				>
					<p>
						Your Google Business Profile has become disconnected. Please reconnect your account to continue viewing and
						responding to your Google reviews through Levitate.
					</p>
				</IntegrationCard>
			)}
		</>
	);
};
