import { StyleSheet } from 'aphrodite';
import { brandSecondary, darkGrayFontColor, grayIconFill, inputBorderColor, mention, white } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
	},
	dropdownAnchorText: {
		maxWidth: 600,
	},
	dropdownIcon: {
		height: 16,
		marginLeft: 12,
		transform: 'rotate(90deg)',
		width: 10,
	},
	dropdownInline: {
		borderColor: inputBorderColor,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		padding: 10,
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: mention,
		},
		cursor: 'pointer',
		padding: 10,
		...CssSelectors.allChildren({
			color: grayIconFill,
			fontSize: 12,
		}),
	},
	dropdownMenu: {
		background: white,
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		maxHeight: 300,
		overflowY: 'auto',
		width: '100% !important',
	},
	modalBodyText: {
		color: '#4A4A4A',
		fontSize: 14,
		lineHeight: '18px',
		marginTop: 10,
	},
	selectAnEmployeeModal: {
		maxWidth: 360,
	},
	searchEmployeeBox: {
		fontSize: 14,
		marginTop: 16,
	},
	selectAnEmployeeCTA: {
		marginTop: 100,
	},
	sendFromDropdown: {
		marginLeft: 8,
		maxWidth: 260,
		width: '80%',
	},
	sendFromDropdownContainer: {
		alignItems: 'center',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 14,
		width: '100%',
	},
	title: {
		color: brandSecondary,
		fontSize: 22,
		fontWeight: 600,
		maxWidth: '80%',
	},
});
