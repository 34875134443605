import { StyleSheet } from 'aphrodite';
import { CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.horizontalStack(6),
	},
	star: {},
	starAnimatable: {
		transition: 'all 0.2s ease',
	},
	starHover: {
		':hover': {
			transform: 'scale3d(1.2, 1.2, 1)',
		},
	},
	emojiRating: {
		fontSize: 44,
		...CssSelectors.allChildren(
			{
				width: 44,
				height: 58,
			},
			'picture'
		),
		...CssSelectors.allChildren(
			{
				fontSize: 22,
				marginBottom: 16,
				minHeight: 27,
			},
			'figcaption'
		),
	},
});
