// eslint-disable-next-line import/no-internal-modules
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { IFileAttachment, IImpersonationContext, IPostTarget } from '../../../../extViewmodels';
import { getContentLength, getShortenedString } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { SocialMediaImagesPreviewModal } from '../SocialMediaImagesPreviewModal';
import { PreviewImages, PreviewPostTargets } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	postTargets: IPostTarget[];
	styles?: StyleDeclarationValue[];
	overlayLogo?: boolean;
	postContent?: string;
	postImages?: IFileAttachment[];
	impersonationCtx: IImpersonationContext;
	hideWhiteBackground?: boolean;
}

export const PreviewSocialMediaPost = ({
	className = '',
	children,
	styles = [],
	postTargets,
	overlayLogo,
	postContent,
	postImages,
	impersonationCtx,
	hideWhiteBackground,
}: React.PropsWithChildren<IProps>) => {
	const [initialImageUrl, setInitialImageUrl] = React.useState<string>('');
	const [imagePreviewModalOpen, setImagePreviewModalOpen] = React.useState(false);
	const userSession = useUserSession();
	const account = impersonationCtx?.account || userSession.account;
	const logoUrl = account?.preferences?.logo?.url;

	const onClickEnlargeImage = (initialUrl: string) => {
		setInitialImageUrl(initialUrl);
		setImagePreviewModalOpen(true);
	};

	const [targetIndex, setTargetIndex] = React.useState(0);
	const onSetTargetPreview = (index?: number) => () => {
		setTargetIndex(index);
	};

	// Update target index back to zero when the targets input has changed (when they are desellected)
	React.useEffect(() => {
		setTargetIndex(0);
	}, [postTargets]);

	const contentDisplayLength = getContentLength(postTargets?.[targetIndex]?.provider);
	const displayContent =
		postContent?.length > contentDisplayLength
			? getShortenedString(postContent.trim(), contentDisplayLength).trim()
			: postContent?.trim();

	if (!postTargets) {
		return null;
	}

	return (
		<div className={`preview-social-media-post ${className} ${css(styleSheet.previewContentContainer, ...styles)}`}>
			<section className={css(styleSheet.previewContentSection)}>
				<h3 className={css(styleSheet.previewHeader)}> PREVIEW POST </h3>
				<PreviewPostTargets
					postTargets={postTargets}
					onSetTargetPreview={onSetTargetPreview}
					targetIndex={targetIndex}
				/>
				<PreviewImages
					postImages={postImages}
					postContent={postContent}
					postTargets={postTargets}
					targetIndex={targetIndex}
					displayContent={displayContent}
					contentDisplayLength={contentDisplayLength}
					onClickEnlargeImage={onClickEnlargeImage}
				/>
				<span className={css(styleSheet.disclaimerText)}>
					This is only a preview. Content may display slightly differently when published.
				</span>
			</section>
			{children}
			<SocialMediaImagesPreviewModal
				modalProps={{
					isOpen: imagePreviewModalOpen,
					onRequestClose: () => setImagePreviewModalOpen(false),
				}}
				initialImageUrl={initialImageUrl}
				images={postImages}
				showOverlayLogo={overlayLogo && !postImages?.every(x => x.hasLogoOverlay)}
				logoUrl={logoUrl}
				hideWhiteBackground={hideWhiteBackground}
			/>
		</div>
	);
};
