import * as Api from '@ViewModels';
import { useMutation } from '@tanstack/react-query';
import { IContentCalendarSelectedSuggestion } from '../../../../../../models';
import { useUserSession } from '../../../../../../models/hooks/appStateHooks';

// Note: Controller does not support impersonation
const getSystemJob = (userSession: Api.UserSessionContext, jobId: string) => {
	return userSession.webServiceHelper.callAsync<Api.IBlogGenerationSystemJob>(
		Api.ImpersonationBroker.composeApiUrl({ urlPath: `SystemJob/${jobId}` }),
		'GET'
	);
};

const schedulePost = (
	userSession: Api.UserSessionContext,
	post: Api.IBlogPost,
	forUserId?: string,
	contentCalendarSuggestionId?: string,
	impersonationContext?: Api.IImpersonationContext
) => {
	return userSession.webServiceHelper.callAsync<Api.IBlogPost>(
		Api.ImpersonationBroker.composeApiUrl({
			impersonationContext,
			urlPath: `blog`,
			queryParams: {
				forUserId,
				contentCalendarSuggestionId,
			},
		}),
		'PUT',
		post
	);
};

const pollSystemJob = async (userSession: Api.UserSessionContext, jobId: string, cancelToken?: AbortSignal) => {
	let systemJob: Api.IBlogGenerationSystemJob = null;
	while (!systemJob?.generatedContent) {
		systemJob = await getSystemJob(userSession, jobId);
		if (cancelToken?.aborted) {
			systemJob = null;
			break;
		}

		if (systemJob?.percentComplete === 100) {
			if (systemJob.generatedContent) {
				return systemJob;
			} else {
				throw Api.asApiError('Blog generation failed');
			}
		}

		await new Promise(resolve => setTimeout(resolve, 3000));
		if (cancelToken?.aborted) {
			systemJob = null;
			break;
		}
	}

	return null;
};

export type ScheduleBlogSuggestionsParams = {
	forUserId?: string;
	cancelToken?: AbortSignal;
	impersonationContext?: Api.IImpersonationContext;
	sendWithCompliance?: boolean;
	sendWithComplianceEmail?: string;
	suggestions: IContentCalendarSelectedSuggestion[];
	templates: Api.IBlogTemplate[];
};

export function useScheduleBlogSuggestions({
	onError,
	onSuccess,
}: {
	onError?: (error: Api.IOperationResultNoValue) => void;
	onSuccess?: (result: PromiseSettledResult<Api.IBlogPost>[], params: ScheduleBlogSuggestionsParams) => void;
}) {
	const userSession = useUserSession();
	return useMutation({
		mutationFn: ({
			cancelToken,
			forUserId,
			impersonationContext,
			sendWithCompliance = false,
			sendWithComplianceEmail,
			suggestions,
			templates,
		}: ScheduleBlogSuggestionsParams) => {
			return Promise.allSettled<Api.IBlogPost | null>(
				suggestions.map(suggestion => {
					return userSession.webServiceHelper
						.callAsync<Api.IBlogGenerationSystemJob>(
							Api.ImpersonationBroker.composeApiUrl({
								impersonationContext,
								urlPath: `blog/generateFromTemplate`,
							}),
							'POST',
							templates.find(t => t.id === suggestion.templateReference.templateId)
						)
						.then(async systemJob => {
							const jobWithPost = systemJob.generatedContent
								? systemJob
								: await pollSystemJob(userSession, systemJob.id, cancelToken);
							if (jobWithPost && jobWithPost.generatedContent && !cancelToken?.aborted) {
								const post = jobWithPost.generatedContent.content;
								post.scheduledSendDate = suggestion.schedule.startDate;
								post.sendWithCompliance = sendWithCompliance;
								post.sendWithComplianceEmail = sendWithComplianceEmail;

								return schedulePost(userSession, post, forUserId, suggestion.id, impersonationContext);
							}
							return null;
						});
				})
			);
		},
		onError,
		onSuccess,
	});
}
